import React, { useState, useEffect } from "react";
import { Layout, Button, Drawer } from "antd";
import RightMenu from "./RightMenu";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";

import "./index.css";

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };

  // If you do not want to auto-close the mobile drawer when a path is selected
  // Delete or comment out the code block below
  // From here
  let { pathname: location } = useLocation();
  useEffect(() => {
    setVisible(false);
  }, [location]);
  // Upto here

  return (
    <nav className="navbar">
      <Layout className="nav">
        <Layout.Sider className="nav-logo-wrapper">
          <div className="nav-logo">
            <img src="/assets/nav-logo.svg" alt="" className="nav-logo-img" />
          </div>
        </Layout.Sider>
        <Layout.Content>
          <div className="navbar-menu">
            <Button className="menuButton" onClick={showDrawer}>
              <MenuOutlined />
            </Button>
            <div className="rightMenu">
              <RightMenu mode={"horizontal"} />
            </div>

            <Drawer
              title={"Brand Here"}
              placement="right"
              closable={true}
              onClose={showDrawer}
              visible={visible}
              style={{ zIndex: 99999 }}
            >
              <RightMenu mode={"inline"} />
            </Drawer>
          </div>
        </Layout.Content>
      </Layout>
    </nav>
  );
};

export default Navbar;
