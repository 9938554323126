import {ADD_NEW_MEMBER, UPDATE_MEMBER, DELETE_MEMBER} from "./memberActionType";

const initialState = {
    memberId: '',
    member: {}
};

const MemberReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_MEMBER:
            return {
                ...state,
                memberId: action.member._id,
                member: action.member
            };

        case UPDATE_MEMBER:
            return {
                ...state,
                member: {...state.member, ...action.member},
            };
        case DELETE_MEMBER:
            return  {
                ...state,
                member: {},
                memberId: ''
            }
        default:
            return state;
    }
};

export default MemberReducer;
