import React, { useEffect, useState } from 'react'
import BasicDetails from './BasicDetails'
import './BasicDetails.css'
import BrandDetails from './BrandDetails'
import ReviewDetails from './ReviewDetails'
import PortfolioDetails from './portfolioDetails'
import { useLocation } from 'react-router'

function MemberEdit() {
  const {state} = useLocation();
  const [loading, setLoading] = useState(true);
  const [formPage , setFormPage] = useState(1);
  const [basicDetails , setBasicDetails] = useState({});
  const [brandDetails , setBrandDetails] = useState({});
  const [reviewDetails , setReviewDetails] = useState({});
  const [protfolioDetails , setProtfolioDetails] = useState({});

  useEffect(() => {
    setBasicDetails(state);
    setLoading(false);
  }, [state]);

  const formPageControl = (formPage) => {
    if (loading) return <div></div>;
    switch(formPage) {
      case 1:
        return (<BasicDetails basicDetails={basicDetails} formPage={formPage} setFormPage={setFormPage} setBasicDetails={setBasicDetails} />);
      case 2:
        return (<BrandDetails formPage={formPage} setFormPage={setFormPage} setBrandDetails={setBrandDetails} />);
      case 3:
        return (<ReviewDetails formPage={formPage} setFormPage={setFormPage} setReviewDetails={setReviewDetails} />);
      case 4:
        return (<PortfolioDetails  formPage={formPage} setFormPage={setFormPage} setProtfolioDetails={setProtfolioDetails}  />);
      default:
      {
        console.log( {
          basicDetails : basicDetails,
          brandDetails : brandDetails,
          reviewDetails :reviewDetails,
          protfolioDetails : protfolioDetails
        } )
      }
    };
  }

  return (
    <div className="members-edit-page-wrapper">
      {
        formPageControl(formPage)
      }
      {/* {
        console.log(basicDetails)
      } */}
      
    </div>
  )
}

export default MemberEdit