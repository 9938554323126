import React, { useEffect, useState } from "react";
import {
  Button,
  Progress,
  Form,
  Input,
  Row,
  Col,
  Select,
  ConfigProvider,
  Breadcrumb,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import callPostApi from "../../components/ApiCallers/CallPostApi";
import {createNewMember, handleCreateMember, handleUpdateMember, updateMemberData} from "../../memberAction";
import { useParams, useNavigate } from "react-router";
import { callApi } from "../../../../util/apiCallerV2";
import { useSelector, useDispatch } from "react-redux";
import {showNotification} from "../../../../util/Utility";

const { Option } = Select;

function BasicDetails() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  // const [user, setUser] = useState({
  //   contact_name:"",
  //   contact_phone:"",
  //   contact_email:"",
  //   country_code:"",
  //   country:"",
  //   city:"",
  //   is_whatsapp_opt_in:""

  // })
  const {userId} = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {memberId, member} = useSelector(state => state.memberReducer)

  useEffect(() => {
    setLoading(true);
    callApi(`customer/${userId}/profile/info`).then((data) => {
      setData(data.data.customer);
      setLoading(false);
    });
  }, [userId]);

  const saveData = async (values) => {
   // console.log("Success Basic Details :", values);
    //if(values){
      if(userId && values) {
        let res = await handleUpdateMember(values, userId);
         if(res.status === 'Success') {
             dispatch(updateMemberData(res.data.customer));
             navigate(-1);
            
         } else {
             showNotification('error', res?.data?.message ? res.data.message : 'Server Error')
         }
      
    }
   
  //}
  }
  // const editUserHandler = (keyName, keyValue) => {
  //   let update = {}
  //   update[keyName] = keyValue;
  //   setUser(update);
  // }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="member-edit-general-wrapper">
      <Breadcrumb
        items={[
          {
            title: (
              <a href="/members" style={{ color: "#1677FF" }}>
                <ArrowLeftOutlined
                  style={{ marginRight: "10px", width: "15px", height: "10px" }}
                />
                Members
              </a>
            ),
          },
          {
            title: <span style={{ color: "#8C8C8C" }}>Basic Details</span>,
          },
        ]}
        className="member-edit-general-breadcrumb"
      />

      <div className="member-edit-general-form-wrapper">
        <h1 className="member-edit-general-form-heading">
          Enter Basic Details
        </h1>
        <div className="member-edit-general-form-steps">
          <span style={{ color: "#FAAD14" }}>Step 1 </span>of 4
        </div>
        <Progress
          percent={25}
          showInfo={false}
          strokeColor="#FAAD14"
          className="member-edit-general-form-progress-bar"
        />
        {!loading && <Form
          className="member-edit-general-form"
          name="Basic Details"
          layout="vertical"
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={data}
          onFinish={saveData}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item
                label={
                  <h1 className="member-edit-form-general-label">Full Name</h1>
                }
                name="contact_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name!",
                  },
                ]}
              >
                <Input className="member-edit-form-general-input-box" 
                //value={user?.contact_name} onChange={(e) => editUserHandler("contact_name" ,e)} 
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <h1 className="member-edit-form-general-label">
                    Email Address
                  </h1>
                }
                name="contact_email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                ]}
              >
                <Input
                  type="email"
                  className="member-edit-form-general-input-box"
                  //value={user?.contact_email} onChange={(e) => editUserHandler("contact_email" ,e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <h1 className="member-edit-form-general-label">Contact No.</h1>
              <Row>
                <Col span={4}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          multipleItemHeight: 60,
                        },
                      },
                      token: {
                        colorBgContainer: "#f5f8ff",
                        colorBorder: "#f0f0f0",
                      },
                    }}
                  >
                    <Form.Item
                      name="country_code"
                      rules={[
                        {
                          required: true,
                          message: "Please select country code!",
                        },
                      ]}
                    >
                      <Select className=""  
                      //value={user?.country_code} onChange={(e) => editUserHandler("country_code" ,e.target.value)}
                      >
                        <Option value="+91">+91</Option>
                        <Option value="+161">+161</Option>
                      </Select>
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col offset={1} span={19}>
                  <Form.Item
                    name="contact_phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your contact number!",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      className="member-edit-form-general-input-box"
                      //value={user?.contact_phone} onChange={(e) => editUserHandler("contact_phone" ,e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      multipleItemHeight: 60,
                    },
                  },
                  token: {
                    colorBgContainer: "#f5f8ff",
                    colorBorder: "#f0f0f0",
                  },
                }}
              >
                <Form.Item
                  label={
                    <h1 className="member-edit-form-general-label">Country</h1>
                  }
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please select country !",
                    },
                  ]}
                >
                  <Select className=""  
                  //value={user?.country} onChange={(e) => editUserHandler("country" ,e.target.value)}
                  >
                    <Option value="usa">USA</Option>
                    <Option value="canada">Canada</Option>
                    <Option value="uk">UK</Option>
                  </Select>
                </Form.Item>
              </ConfigProvider>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      multipleItemHeight: 60,
                    },
                  },
                  token: {
                    colorBgContainer: "#f5f8ff",
                    colorBorder: "#f0f0f0",
                  },
                }}
              >
                <Form.Item
                  label={
                    <h1 className="member-edit-form-general-label">City</h1>
                  }
                  name="city"
                >
                  <Select className=""  
                  //</Form.Item>value={user?.city} onChange={(e) => editUserHandler("city" ,e.target.value)}
                  >
                    <Option value="hyd">Hyderabad</Option>
                    <Option value="del">Delhi</Option>
                    <Option value="ban">Banglore</Option>
                  </Select>
                </Form.Item>
              </ConfigProvider>
            </Col>
            <Col span={12}>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      multipleItemHeight: 60,
                    },
                  },
                  token: {
                    colorBgContainer: "#f5f8ff",
                    colorBorder: "#f0f0f0",
                  },
                }}
              >
                <Form.Item
                  label={
                    <h1 className="member-edit-form-general-label">
                      WhatsApp Opt
                    </h1>
                  }
                  name="is_whatsapp_opt_in"
                >
                  <Select className=""  
                  //value={user?.is_whatsapp_opt_in} onChange={(e) => editUserHandler("is_whatsapp_opt_in" ,e.target.value)}
                  >
                    <Option value={true}>YES</Option>
                    <Option value={false}>NO</Option>
                  </Select>
                </Form.Item>
              </ConfigProvider>
            </Col>
          </Row>
          <Row className="member-edit-form-general-buttons-wrapper">
            <Col span={6} offset={6}>
              <Button onClick={() => navigate(-1)} className="member-edit-form-general-prev-button">
                Cancel
              </Button>
            </Col>
            <Col offset={1} span={6}>
              <Button
                className="member-edit-form-general-next-button"
                htmlType="submit"
              >
                Save & Proceed
              </Button>
            </Col>
          </Row>
        </Form>}
      </div>
    </div>
  );
}

export default BasicDetails;
