import React, { useState } from "react";

import {
  Typography,
  Popover,
  Menu,
  Space,
  Modal,
  Input,
  DatePicker,
  TimePicker,
  Button,
} from "antd";
import {
  MoreOutlined,
  CalendarFilled,
  ClockCircleFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import "./ScheduledMessageCard.css";

const { Text } = Typography;
const { TextArea } = Input;

const OnDateChange = (date, dateString) => {
  console.log(date, dateString);
};

dayjs.extend(customParseFormat);
const onTimeChange = (time, timeString) => {
  console.log(time, timeString);
};

const ScheduledMessageCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCardMenuOpen, setCardMenuOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setCardMenuOpen(newOpen);
  };

  const showModal = () => {
    setCardMenuOpen(false);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const SideChat3DotsMenu = (
    <div style={{ width: "100px" }}>
      <Menu>
        <Menu.Item key="edit" onClick={showModal}>
          Edit
        </Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </Menu>
    </div>
  );

  return (
    <div className="scheduled-message-card-wrapper">
      <div className="scheduled-message-card-header">
        <Text className="scheduled-message-card-heading">David Moore</Text>

        <Popover
          placement="bottomRight"
          content={SideChat3DotsMenu}
          trigger="click"
          open={isCardMenuOpen}
          onOpenChange={handleOpenChange}
        >
          <MoreOutlined style={{ color: "#1F1F1F", fontSize: "16px" }} />
        </Popover>
        <Modal
        centered
          title={
            <Space direction="vertical" size={8}>
              <Text className="schedule-message-card-edit-modal-title">
                Schedule A Message
              </Text>
              <Text className="schedule-message-card-edit-modal-title-name">
                David Moore
              </Text>
            </Space>
          }
          footer={null}
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Space direction="vertical" size={34} style={{marginTop : '30px' , width : '100%'}}>
            <Space size={24}>
              <Space direction="vertical" size={12}>
                <Text className="schedule-message-card-edit-modal-general-heading">
                  Scheduling Date
                </Text>
                <DatePicker
                  onChange={OnDateChange}
                  suffixIcon={<CalendarFilled style={{ color: "#1677FF" }} />}
                  style={{borderColor : '#F0F0F0'}}
                />
              </Space>
              <Space direction="vertical" size={12}>
                <Text className="schedule-message-card-edit-modal-general-heading">
                  Scheduling Time
                </Text>
                <TimePicker
                  suffixIcon={
                    <ClockCircleFilled style={{ color: "#1677FF" }} />
                  }
                  onChange={onTimeChange}
                  defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                  style={{borderColor : '#F0F0F0'}}
                />
              </Space>
            </Space>
            <Space direction="vertical" size={12} style={{width : '100%'}} >
              <Text className="schedule-message-card-edit-modal-general-heading">
                Message
              </Text>
              <TextArea
                maxLength={100}
                style={{ height: '120px' , resize: "none" ,borderColor : '#F0F0F0'}}
                placeholder="Type your message here"
              />
            </Space>
            <Button className="schedule-message-card-edit-modal-button" >Schedule Message</Button>
          </Space>
         
        </Modal>
      </div>
      <div className="scheduled-message-card-body">
        <Space direction="vertical">
          <Space>
            <Text className="scheduled-message-card-text">02/09/2023</Text>|
            <Text className="scheduled-message-card-text">10:00 AM</Text>
          </Space>
          <Space>
            <Text className="scheduled-message-card-text">
              Maecenas nibh ullamcorper cursus tortor ac cras pellentesque et.
              Convallis quis donec nisi ornare. Tortor aliquet.
            </Text>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default ScheduledMessageCard;
