import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./css/header.css";
import "./css/login.css";
import "./css/admin.css";
import "./css/blog.css";
import "./css/modal.css";
import "./css/addmember.css";

//Admin
import AdminLogin from "./modules/Admin/pages/Login/LoginPage";
import AdminProfile from "./modules/Admin/pages/Profile/AdminProfile";
import MemberListPage from "./modules/Members/pages/MemberList/MemberListPage";

import { ToastContainer } from "react-toastify";
import {
  setAuthentication,
  setAuthState,
  setUserId,
  setUserType,
} from "./modules/appAction";

import {
  AUTHENTICATED,
  AUTHENTICATING,
  NOT_AUTHENTICATED,
} from "./constants/authEnums";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
import { APP_TOKEN, USER_ID, USER_TYPE } from "./constants/appEnums";
import { fetchAdminProfileDetail } from "./modules/Admin/adminAction";
import BasicDetails from "./modules/Members/pages/MemberEdit/BasicDetails";
import PortfolioDetails from "./modules/Members/pages/MemberEdit/portfolioDetails";
import BrandDetails from "./modules/Members/pages/MemberEdit/BrandDetails";
import MemberEdit from "./modules/Members/pages/MemberEdit";
import MemberListTable from "./modules/Members/components/MemberListTable";
import ChatDashboard from "./modules/Members/pages/Chat/ChatDashboard";
import MemberAdd from "./modules/Members/pages/MemberAdd/MemberAdd";

function App() {
  const dispatch = useDispatch();

  const token = localStorage.getItem(APP_TOKEN);
  const userType = localStorage.getItem(USER_TYPE);
  const userId = localStorage.getItem(USER_ID);

  const authState = useSelector((state) => state?.appReducer?.authState);

  useEffect(() => {
    try {
      dispatch(setAuthState(AUTHENTICATING));
      // get user from the payload of jwt token
      if (token) {
        dispatch(setAuthState(AUTHENTICATED));
        dispatch(setAuthentication(true));
        dispatch(setUserId(userId));
        dispatch(setUserType(userType));
        dispatch(fetchAdminProfileDetail(userId));
      } else {
        dispatch(setAuthState(NOT_AUTHENTICATED));
        dispatch(setAuthentication(false));
      }
    } catch (err) {
      dispatch(setAuthState(NOT_AUTHENTICATED));
      dispatch(setAuthentication(false));
    }
  }, []);

  const AuthenticatedRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          <Route path="/login" element={<AdminLogin />} />

          {/* Admin */}
          <Route path={"/"} element={<MemberListPage />}>
            <Route path={"members"} element={<MemberListTable />} />
            <Route path={"members/edit/:userId"} element={<BasicDetails />} />
            <Route path={"members/edit/:userId/portfolio"} element={<PortfolioDetails />} />
            <Route path={"members/brand/:brandId"} element={<BrandDetails />} />
            <Route path={"members/add"} element={<MemberAdd />} />
            <Route path={"chat"} element={<ChatDashboard />} />
            <Route path={"vision-search"} element={<MemberListTable />} />
            <Route path={"jobs"} element={<MemberListTable />} />
            <Route path={"profile"} element={<AdminProfile />} />
          </Route>
        </Routes>
      </Router>
    );
  };

  const NotAuthenticatedRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          <Route path="/login" element={<AdminLogin />} />
        </Routes>
      </Router>
    );
  };

  const getRoutes = () => {
    switch (authState) {
      case AUTHENTICATED:
        return <AuthenticatedRoutes />;
      case NOT_AUTHENTICATED:
        return <NotAuthenticatedRoutes />;
      case AUTHENTICATING:
        return <FullPageLoader />;
      default:
        return <FullPageLoader />;
    }
  };

  return (
    <React.Fragment>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Suspense fallback={<FullPageLoader />}>{getRoutes()}</Suspense>
        </ThemeProvider>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
}

export default App;
