import { createSlice } from '@reduxjs/toolkit';

// Define the extended initial state for the chat dashboard
const initialState = {
  messages: [],
  groups: [],
  activeUser: null,
  activeUserId : '',
  SideBar: 'CHATS',
  MainArea : 'EMPTY',
  broadcastList : 'SELECT_BROADCAST_LIST'
};

// Create a slice using createSlice
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // Define action creators to modify the state
    setMessages: (state, action) => {
      state.messages = (action.payload);
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setActiveUserID: (state, action) => {
        state.activeUserId = action.payload;
      },
    setSideBar: (state, action) => {
      state.SideBar = action.payload;
    },
    setMainArea: (state, action) => {
        state.MainArea = action.payload;
      },
      setBroadcastList: (state, action) => {
        state.broadcastList = action.payload;
      },
  },
});

// Export action creators
export const {
  setMessages,
  setGroups,
  setActiveUser,
  setActiveUserID,
  setSideBar,
  setMainArea,
  setBroadcastList
} = chatSlice.actions;

// Create a reducer
export default chatSlice.reducer;
