import React from "react";

import { Layout } from "antd";

import SideChats from "./SideChats";
import ChatMainArea from "./ChatMainArea";
import CreateBroadcastListSide from "./CreateBroadcastListSide";

import { useDispatch, useSelector } from "react-redux";

const { Content, Sider } = Layout;

const SideBarComponent = (sideBar) => {
  switch (sideBar) {
    case "CHATS":
      return <SideChats />;
      break;
    case "CREATE_BROADCAST_LIST":
      return <CreateBroadcastListSide />;
      break;
    default:
      return <SideChats />;
      break;
  }
};

const MainAreaComponent = (mainArea) => {
  switch (mainArea) {
    case 'EMPTY':
      return null
      break;
    case 'CHAT_AREA':
      return <ChatMainArea />
    default:
      return null
      break;
  }
}

const ChatDashboard = () => {
  const dispatch = useDispatch();
  const chatState = useSelector((state) => state.chatReducer);

  return (
    <div>
      <Layout>
        <Sider
          width="26vw"
          style={{
            overflow: "auto",
            height: "85vh",
            position: "relative",
            left: 0,
            top: 0,
            bottom: 0,
          }}
          className="hideScroll"
        >
          {
            SideBarComponent(chatState.SideBar)
          }
        </Sider>
        <Content
          style={{
            height: "85vh",
            background : '#EDF3FF'
          }}
        >
          {console.log(chatState)}
          {
            MainAreaComponent(chatState.MainArea)
          }
        </Content>
      </Layout>
    </div>
  );
};

export default ChatDashboard;
