import React from "react";
import {Tag } from "antd";

import './index.css'

function TagMemberList({value , style}) {
    // const userTypeToColor = {
    //     Architect : ['#FFF6E4' , '#FFA500'],
    //     Customer : ['#E5F7ED' , '#00AC4F'],
    //     "Design Enthusiast" : ['#FFE8FF' , '#800080'],
    //     Vendor : ['#FFEBE8' , '#F05644']
    // }

    // const styles = {
    //   color : `${userTypeToColor[`${value}`][1]}`,
    //   ...style
    // }

    const styles = {
      color : `#FFA500`,
      ...style
    }
    
  return (
    <>
      {/* <Tag color={userTypeToColor[`${value}`][0]} style={styles} className="member-list-table-tag">{value}</Tag> */}
      <Tag color="#FFF6E4" style={styles} className="member-list-table-tag">{value}</Tag>
    </>
  );
}

export default TagMemberList;
