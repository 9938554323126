export default function AddMemberModalSelection(props) {
    return (
        <div className={'add-member-modal-selection-container'}>
            {/* eslint-disable-next-line no-undef */}
            <div onClick={() => props.setMemberType(_.kebabCase(props.title))} className={`add-member-modal-selection-image-container${props.memberType === _.kebabCase(props.title) ? '-active' : ''}`}>
                <img className={'add-member-modal-image'} src={props.image} alt={props.title} />
            </div>
            <p className={'add-member-modal-image-title'}>{props.title}</p>
        </div>
    )
}
