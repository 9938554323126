import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import { adminLogin } from "../../adminAction";

import { Input, Button } from "antd";

const LeftAside = styled(Box)`
  background-image: url("/assets/login_frame.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 539px;
  height: 650px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 55px 35px;
`;

const DetailsBody = styled.div`
  margin-top: 30px;
`;

const GeneralLink = styled(Link)`
  text-decoration: unset;
`;

const LoginPage = () => {
    const dispatch = useDispatch();

    const [userDetails, setUserDetails] = useState({});
    const [isNext, setIsNext] = useState(false);

    const stepValidation = () => {
        return !!userDetails?.loginId && !!userDetails?.password;
    };

    useEffect(() => {
        if (userDetails) {
            setIsNext(stepValidation());
        }
    }, [userDetails]);

    const submitOtpHandle = () => {
        const body = {
            contact_email: userDetails?.loginId,
            password: userDetails?.password,
        };

        if (body) {
            dispatch(adminLogin(body));
        }
    };

    return (
        <Box display="flex" width="100%">
            <Box width="50%">
                <LeftAside>
                    <img src="/assets/logo.svg" alt="" />
                </LeftAside>

            </Box>
            <Box width="50%" display="flex" alignItems="center">
                <LoginModal>
                    <Label fontSize="20px" fontWeight={500}>
                        Welcome to Archinza
                    </Label>
                    <Label fontSize="50px" fontWeight={700}>
                        Sign in
                    </Label>
                    <DetailsBody>
                        <Label variant="text7" color="#8C8C8C" mb="15px">
                            Email Address
                        </Label>
                        <Input
                            value={userDetails?.loginId}
                            onChange={(e) =>
                                setUserDetails({ ...userDetails, loginId: e.target.value })
                            }
                            placeholder={"Enter email address"}
                            type="text"
                            style={{ height: "57px" }}
                        />
                        <Label variant="text7" color="#8C8C8C" mt="45px" mb="15px">
                            Password
                        </Label>
                        <Input.Password
                            value={userDetails?.password}
                            onChange={(e) =>
                                setUserDetails({ ...userDetails, password: e.target.value })
                            }
                            placeholder="Enter password"
                            type="text"
                            style={{ height: "57px" }}
                        />
                    </DetailsBody>
                    <Box display="flex" justifyContent="flex-end" mt="17px">
                        <a href="" style={{ fontSize: "14px", fontWeight: 400 }}>Forgot Password</a>
                    </Box>

                    <Box display="flex" justifyContent="center" mt="30px">
                        <Button
                            block
                            type="primary"
                            onClick={submitOtpHandle}
                            style={{height: "54px"}}
                        >
                            Login Now
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="center" mt="30px">
                        <Label variant="text4" color="#8C8C8C">
                            Don’t have an Account ? &nbsp;
                            <a href="">Register</a>
                        </Label>
                    </Box>
                </LoginModal>
            </Box>
        </Box>
    );
};

export default LoginPage;
