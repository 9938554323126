import React from "react";
import {
  Button,
  Progress,
  Form,
  Input,
  Row,
  Col,
  Select,
  ConfigProvider,
  Breadcrumb,
  Space,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteFilled,
  PlusOutlined,
} from "@ant-design/icons";
const { Option } = Select;

function ReviewDetails({ formPage, setFormPage, setReviewDetails }) {
  const onPrevClick = () => {
    setFormPage(formPage - 1);
  };
  const onFinish = (values) => {
    console.log("Success Review Details :", values);
    setReviewDetails(values);
    setFormPage(formPage + 1);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="member-edit-general-wrapper">
      <Breadcrumb
        items={[
          {
            title: (
              <a href="/members" style={{ color: "#1677FF" }}>
                <ArrowLeftOutlined
                  style={{ marginRight: "10px", width: "15px", height: "10px" }}
                />
                Members
              </a>
            ),
          },
          {
            title: <span style={{ color: "#8C8C8C" }}>Add Members</span>,
          },
        ]}
        className="member-edit-general-breadcrumb"
      />

      <div className="member-edit-general-form-wrapper">
        <h1 className="member-edit-general-form-heading">Enter Reviews</h1>
        <div className="member-edit-general-form-steps">
          <span style={{ color: "#FAAD14" }}>Step 3 </span>of 4
        </div>
        <Progress
          percent={75}
          showInfo={false}
          strokeColor="#FAAD14"
          className="member-edit-general-form-progress-bar"
        />
        <Form
          className="member-edit-general-form"
          name="Review Details"
          layout="vertical"
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.List
            name="reviews"
            initialValue={[{}]}
            rules={[
              {
                validator: async (_, reviews) => {
                  if (!reviews || reviews.length < 1) {
                    return Promise.reject(
                      new Error("At least one category is required.")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <div key={`div${index}`}>
                    <h1
                      className="member-edit-general-form-heading"
                      key={`heading${index}`}
                    >
                      {index + 1}. Customer Review
                    </h1>
                    {fields.length > 1 ? (
                      <div
                        className="member-edit-general-form-remove-icon-wrapper"
                        onClick={() => remove(field.name)}
                      >
                        <Space>
                          <p className="member-edit-general-form-remove-icon-delete">
                            Delete
                          </p>
                          <DeleteFilled style={{ color: "#FF0000" }} />
                        </Space>
                      </div>
                    ) : null}
                    <Row gutter={[40, 30]} style={{ marginTop: "34px" }}>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              User Name
                            </h1>
                          }
                          name={[field.name, "user_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your user name!",
                            },
                          ]}
                        >
                          <Input className="member-edit-form-general-input-box" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                multipleItemHeight: 60,
                              },
                            },
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                User Type
                              </h1>
                            }
                            name={[field.name, "user_type"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select user type",
                              },
                            ]}
                          >
                            {/* Update the Select component */}
                            <Select className="member-edit-form-general-select">
                              <Option value="cus">Customer</Option>
                              <Option value="ven">Vendor</Option>
                              <Option value="arch">Architect</Option>{" "}
                              {/* Correct the typo here */}
                            </Select>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: "34px" }}>
                      <Col span={6}>
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                multipleItemHeight: 60,
                              },
                            },
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Overall Rating
                              </h1>
                            }
                            name={[field.name, "overall_rating"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select overall rating",
                              },
                            ]}
                          >
                            {/* Update the Select component */}
                            <Select className="member-edit-form-general-select">
                              <Option value="1">1 Star</Option>
                              <Option value="2">2 Stars</Option>
                              <Option value="3">3 Stars</Option>{" "}
                              <Option value="4">4 Stars</Option>
                              <Option value="5">5 Stars</Option>
                              {/* Correct the typo here */}
                            </Select>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                      <Col span={6}>
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                multipleItemHeight: 60,
                              },
                            },
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Design Rating
                              </h1>
                            }
                            name={[field.name, "design_rating"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select design rating",
                              },
                            ]}
                          >
                            {/* Update the Select component */}
                            <Select className="member-edit-form-general-select">
                              <Option value="1">1 Star</Option>
                              <Option value="2">2 Stars</Option>
                              <Option value="3">3 Stars</Option>{" "}
                              <Option value="4">4 Stars</Option>
                              <Option value="5">5 Stars</Option>
                              {/* Correct the typo here */}
                            </Select>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                      <Col span={6}>
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                multipleItemHeight: 60,
                              },
                            },
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Service Rating
                              </h1>
                            }
                            name={[field.name, "service_rating"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select service rating",
                              },
                            ]}
                          >
                            {/* Update the Select component */}
                            <Select className="member-edit-form-general-select">
                              <Option value="1">1 Star</Option>
                              <Option value="2">2 Stars</Option>
                              <Option value="3">3 Stars</Option>{" "}
                              <Option value="4">4 Stars</Option>
                              <Option value="5">5 Stars</Option>
                              {/* Correct the typo here */}
                            </Select>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                      <Col span={6}>
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                multipleItemHeight: 60,
                              },
                            },
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Delivery Rating
                              </h1>
                            }
                            name={[field.name, "delivery_rating"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select delivery rating",
                              },
                            ]}
                          >
                            {/* Update the Select component */}
                            <Select className="member-edit-form-general-select">
                              <Option value="1">1 Star</Option>
                              <Option value="2">2 Stars</Option>
                              <Option value="3">3 Stars</Option>{" "}
                              <Option value="4">4 Stars</Option>
                              <Option value="5">5 Stars</Option>
                              {/* Correct the typo here */}
                            </Select>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: "34px" }}>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              Type Review
                            </h1>
                          }
                          name={[field.name, "review"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your review",
                            },
                          ]}
                        >
                          <Input.TextArea
                            className="member-edit-form-general-input-box"
                            maxLength={100}
                            style={{ height: 170, resize: "none" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    className="member-edit-form-general-add-field-button member-edit-form-review-details-add-field-button"
                    icon={<PlusOutlined />}
                  >
                    ADD ANOTHER REVIEW
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item></Form.Item>
          <Row className="member-edit-form-general-buttons-wrapper">
            <Col span={6} offset={6}>
              <Button
                className="member-edit-form-general-prev-button"
                onClick={onPrevClick}
              >
                Previous Step
              </Button>
            </Col>
            <Col offset={1} span={6}>
              <Button
                className="member-edit-form-general-next-button"
                htmlType="submit"
              >
                Save & Proceed
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default ReviewDetails;
