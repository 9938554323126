import React from "react";

import { Col, Row, Typography } from "antd";

import './ScheduledMessages.css'
import ScheduledMessageCard from "./ScheduledMessageCard";
const { Text } = Typography;

const ScheduledMessages = () => {
  return (
    <div className="scheduled-messages-wrapper">
      <div className="scheduled-messages-header">
        <Text className="scheduled-messages-heading">Scheduled Messages</Text>
      </div>
      <div className="scheduled-messages-card-wrapper">
        <Row gutter={34}>
            <Col span={12}>
            <ScheduledMessageCard />
            </Col>
            <Col span={12}>
            <ScheduledMessageCard />
            </Col>
        </Row>
      </div>
    </div>
  );
};

export default ScheduledMessages;
