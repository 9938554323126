import {Input} from "antd";
import {useEffect, useState} from "react";
import propTypes from "prop-types";

export default function EnterCaptureInput({onEnter, value, onChange, size, style, placeholder}) {
    const [inputText, setInputText] = useState(value);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter' && inputText !== "") {
                onEnter(inputText);
                setInputText("");
            }
        };

        window.addEventListener('keypress', handleKeyPress);

        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [inputText, onEnter]);

    useEffect(() => {
        setInputText(value);
    }, [value]);

    const handleChange = (e) => {
        setInputText(e.target.value)
    }

    return (
        <Input
            size={size}
            style={style}
            placeholder={placeholder}
            value={inputText}
            onChange={onChange ? onChange : handleChange}
        />
    )
}


EnterCaptureInput.propTypes = {
    onEnter: propTypes.func.isRequired,
    value: propTypes.string,
    onChange: propTypes.func,
    size: propTypes.string,
    style: propTypes.object
}
