import {ADD_NEW_MEMBER, DELETE_MEMBER, UPDATE_MEMBER} from "./memberActionType";
import {callApi} from "../../util/apiCallerV2";
import {showNotification} from "../../util/Utility";

export const setMemberData = (member) => ({
  type: ADD_NEW_MEMBER,
  member,
})

export const updateMember = (member) => ({
  type: UPDATE_MEMBER,
  member,
})

export const deleteMemberData = () => ({
  type: DELETE_MEMBER,
})

export const createNewMember = (member) => (dispatch) => {
  try {
      dispatch(setMemberData(member));
  } catch (e) {
    console.log(e)
    throw e;
  }
}

export const updateMemberData = (member) => (dispatch) => {
  try {
    dispatch(updateMember(member));
  } catch (e) {
    console.log(e)
    throw e;
  }
}

export const removeMember = () => (dispatch) => {
  try {
    dispatch(deleteMemberData());
  } catch (e) {
    console.log(e)
    throw e;
  }
}

export const handleUpdateMember = async (member, memberId) => {
  const res = await callApi(`customer/${memberId}/profile/update`,'post',{customer: {...member}})

  return res
}

export  const handleCreateMember = async (member) => {
  const res = await callApi('customer/auth/signup','post',{customer: {...member}})

  return res
}
