import React from "react";
import Navbar from "../../components/Navbar";

import "./index.css";
import { Outlet } from "react-router";

function MemberListPage() {
  return (
    <>
      <div className="member-list-wrapper">
        <Navbar />
        <div className="">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default MemberListPage;
