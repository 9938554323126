import React, {useEffect, useState} from "react";
import {Col, Form, Radio, Row, Space, Button, Select} from "antd";
import {callApi} from "../../../../../util/apiCallerV2";
import _ from "lodash";
import {showNotification} from "../../../../../util/Utility";
import '../memberAdd.css'
import {useDispatch, useSelector} from "react-redux";
import {handleUpdateMember, updateMemberData} from "../../../memberAction";

export default function StStep01({currentStep, totalSteps, nextStep, previousStep, setTotalSteps}) {
    const [value, setValue] = useState();
    const [options, setOptions] = useState([]);
    const [data, setData] = useState({});

    const dispatch = useDispatch();

    const {memberId, member} = useSelector(state => state.memberReducer);

    useEffect(() => {
        callApi('content/code/team-member-job-roles','get').then(res => {
            if(res.status === 'Success' && !_.isEmpty(res?.data?.content)) {
                setOptions(res?.data?.content?.params);
            } else {
                showNotification('error', 'Error fetching options')
            }
        })

        setTotalSteps(totalSteps);
    }, []);

    const handleCancelForm = () => {
        previousStep();
    }

    const handleSubmitForm = async (formData) => {
        let customer = {...formData};

        if(memberId && memberId !== '') {
            let res = await handleUpdateMember(customer, memberId);
            if(res.status === 'Success') {
                dispatch(updateMemberData(res.data.customer));
                nextStep();
            } else {
                showNotification('error', res?.data?.message ? res.data.message : 'Server Error')
            }
        }
    }

    return (
        <div>
            <h1 style={{textAlign: 'center'}} className="member-edit-general-form-heading">What course are you pursuing</h1>
            <Form
                className="member-edit-general-form"
                name="StStep01"
                layout="vertical"
                labelCol={{
                    span: 16,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={handleSubmitForm}
                autoComplete={'off'}
            >
                <Form.Item
                    hasFeedback
                    name={'student_course_applied'}
                    rules={[
                        {
                            required: true,
                            message: 'Please pick an item!',
                        },
                    ]}
                >
                    <Select
                        size={'large'}
                        style={{
                            width: '300px',
                            margin: 'auto',
                            display: 'block'
                        }}
                        options={options}
                        allowClear
                    />
                </Form.Item>
                    <Row className="member-edit-form-general-buttons-wrapper">
                        <Col span={6} offset={6}>
                            <Button
                                onClick={handleCancelForm}
                                className="form-general-prev-button"
                            >
                                {currentStep === 1 ? 'Cancel' : "Back"}
                            </Button>
                        </Col>
                        <Col offset={1} span={6}>
                                <Button
                                    htmlType={'submit'}
                                    className="form-general-next-button"
                                >
                                    {currentStep === totalSteps ? 'Save & Proceed' : "Next Step"}
                                </Button>
                        </Col>
                    </Row>
            </Form>
        </div>
    )
}
