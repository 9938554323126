import React, { useEffect } from "react";
import "./ChatMainArea.css";
import {
  Layout,
  Typography,
} from "antd";
import {

} from "@ant-design/icons";

import ChatProfile from "./ChatProfile";
import ScheduledMessages from "./ScheduledMessages";
import ConversationBlock from "./ConversationBlock";

import { useDispatch, useSelector } from "react-redux";
import { setMessages } from "../../chatSlice";
import {callApi} from "../../../../util/apiCallerV2";

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;


const ChatMainArea = () => {

  const dispatch = useDispatch();
  const chatState = useSelector((state) => state.chatReducer);

  const requestGroupsData = {
    "pageNum": 1,
    "pageSize": 20,
    "filters": {
      customer_id : chatState.activeUserId,
    }
}

  useEffect(() => {
    callApi('message/list' ,"post", requestGroupsData).then((data) => {
      dispatch(setMessages(data.data.messageList))
    });
  } , [chatState.activeUserId]);

  return (
    <div className="chat-main-area-wrapper">
      <Layout
        style={{
          height: "85vh",
          position: "relative",
          left: 0,
          top: 0,
          bottom: 0,
          background: "#EDF3FF",
        }}
      >
        <Content>
        {/* <ScheduledMessages /> */}
         <ConversationBlock />
        </Content>
        <Sider width="24vw">
          <ChatProfile />
        </Sider>
      </Layout>
    </div>
  );
};

export default ChatMainArea;
