import React, {useEffect, useState} from "react";
import {Breadcrumb, Progress,} from "antd";
import {ArrowLeftOutlined,} from "@ant-design/icons";
import StepWizard from "react-step-wizard";
import RegistrationFormStep from "./RegistrationFormStep";
import './memberAdd.css'
import BStep01 from "./BusinessFormSteps/BStep01";
import BStep03 from "./BusinessFormSteps/BStep03";
import BStep02 from "./BusinessFormSteps/BStep02";
import {useSearchParams} from "react-router-dom";
import StStep01 from "./StudentFormSteps/StStep01";
import StStep02 from "./StudentFormSteps/StStep02";
import TmStep03 from "./TeamMemberFormSteps/TmStep03";
import TmStep02 from "./TeamMemberFormSteps/TmStep02";
import TmStep01 from "./TeamMemberFormSteps/TmStep01";
import DStep03 from "./DesignEnthusiastFormSteps/DStep03";
import DStep02 from "./DesignEnthusiastFormSteps/DStep02";
import DStep01 from "./DesignEnthusiastFormSteps/DStep01";
import StStep03 from "./StudentFormSteps/StStep03";
import TmStep04 from "./TeamMemberFormSteps/TmStep04";
import TmStep05 from "./TeamMemberFormSteps/TmStep05";

function MemberAdd() {
    const [currentStep, setCurrentStep] = useState(1);
    const [totalSteps, setTotalSteps] = useState(4);
    const [params, setPrams] = useSearchParams();
    const [activeForm, setActiveForm] = useState('');

    const businessForm = [<BStep01 setTotalSteps={setTotalSteps} />, <BStep02 />, <BStep03 />]
    const studentForm = [<StStep01 setTotalSteps={setTotalSteps} />, <StStep02 />, <StStep03 />]
    const designEnthusiastForm = [<DStep01 setTotalSteps={setTotalSteps} />, <DStep02 />, <DStep03 />]
    const teamMemberForm = [<TmStep01 setTotalSteps={setTotalSteps} />, <TmStep02 />, <TmStep03 />, <TmStep04 />, <TmStep05 />]

    useEffect(() => {
        let member_type = params.get('member_type');
        if(member_type && member_type !== "") {
            setActiveForm(member_type)
        } else {
            setPrams({member_type: 'business'})
        }
    }, [params]);

    const handleFormRender = (data) => {
        let renderData = [];

        switch (data) {
            case 'business':
                renderData = businessForm;
                break
            case 'student':
                renderData = studentForm;
                break
            case 'design-enthusiast':
                renderData = designEnthusiastForm;
                break
            case 'team-member':
                renderData = teamMemberForm;
                break
            default:
                renderData = businessForm
        }

        return renderData
    }

    return (
        <div className="member-edit-general-wrapper">
            <Breadcrumb
                items={[
                    {
                        title: (
                            <a href="/members" style={{ color: "#1677FF" }}>
                                <ArrowLeftOutlined
                                    style={{ marginRight: "10px", width: "15px", height: "10px" }}
                                />
                                Members
                            </a>
                        ),
                    },
                    {
                        title: <span style={{ color: "#8C8C8C" }}>Add Member</span>,
                    },
                ]}
                className="member-edit-general-breadcrumb"
            />

            <div className="member-edit-general-form-wrapper">
                <h1 className="member-edit-general-form-heading">
                    Enter Member Details
                </h1>
                <div className="member-edit-general-form-steps">
                    <span style={{ color: "#FAAD14" }}>Step {currentStep} </span>of {totalSteps}
                </div>
                <Progress
                    percent={currentStep/totalSteps*100}
                    showInfo={false}
                    strokeColor="#FAAD14"
                    className="member-edit-general-form-progress-bar"
                />
                <StepWizard
                    onStepChange={e => setCurrentStep(e.activeStep)}
                >
                    <RegistrationFormStep />
                    {
                        handleFormRender(activeForm).map((Component, index) => (
                            Component
                        ))
                    }
                </StepWizard>
            </div>
        </div>
    );
}

export default MemberAdd;
