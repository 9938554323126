import React, {useEffect, useState} from "react";
import {Col, Form, Radio, Row, Space, Button, Checkbox, Input} from "antd";
import {callApi} from "../../../../../util/apiCallerV2";
import _ from "lodash";
import {showNotification} from "../../../../../util/Utility";
import '../memberAdd.css'
import {useDispatch, useSelector} from "react-redux";
import {handleUpdateMember, removeMember, updateMemberData} from "../../../memberAction";
import {useNavigate} from "react-router-dom";
import EnterCaptureInput from "../../../../../components/Input/EnterCaptureInput";

export default function TmStep03({currentStep, totalSteps, nextStep, previousStep}) {
    const [options, setOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [extraOptions, setExtraOptions] = useState([]);

    const handleAddExtraOptions = (input) => {
        let optionsList = extraOptions.map(option => option.value);
        if(!optionsList.includes(input)) {
            let option = {};
            option._id = extraOptions.length + 1;
            option.label = _.startCase(input);
            option.value = input

            setExtraOptions(prev => [...prev, option]);
        } else {
            showNotification('warning', 'Option already present')
        }
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {memberId, member} = useSelector(state => state.memberReducer);

    useEffect(() => {
        callApi('content/code/team-member-past-work','get').then(res => {
            if(res.status === 'Success' && !_.isEmpty(res?.data?.content)) {
                setOptions(res?.data?.content?.params);
            } else {
                showNotification('error', 'Error fetching options')
            }
        })
    }, []);

    const handleCancelForm = () => {
        previousStep();
    }

    const handleSelect = (value) => {
        let tempValues = [...selectedValues];
        if(tempValues.includes(value)) {
            tempValues.splice(tempValues.indexOf(value), 1);
        } else {
            tempValues.push(value)
        }

        setSelectedValues(tempValues)
    }

    const handleSubmitForm = async (formData) => {
        let customer = {};
        customer.skills_developed = [...selectedValues];

        if(memberId && memberId !== '') {
            let res = await handleUpdateMember(customer, memberId);
            if(res.status === 'Success') {
                dispatch(updateMemberData(res.data.customer));
                nextStep();
            } else {
                showNotification('error', res?.data?.message ? res.data.message : 'Server Error')
            }
        }
    }

    return (
        <div>
            <h1 style={{textAlign: 'center'}} className="member-edit-general-form-heading">What Are Your Largest Concerns | Unmet Needs?
            </h1>
            <Form
                className="member-edit-general-form"
                name="TmStep03"
                layout="vertical"
                labelCol={{
                    span: 16,
                }}
                wrapperCol={{
                    span: 24,
                }}
                autoComplete={'off'}
            >
                <Form.Item
                    hasFeedback
                    name={'requirements'}
                    rules={[
                        {
                            required: true,
                        },
                        ({ getFieldValue }) => ({
                            validator(a, value) {
                                if (!_.isEmpty(selectedValues)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Please pick at least on item!'));
                            },
                        }),
                    ]}
                >
                    <div style={{width: '100%'}}>
                        <Space style={{justifyContent: 'center', margin: 'auto', width: '100%'}} wrap={true} size={32}>
                            {
                                !_.isEmpty(options) ? options.map((option, index) => (
                                    <Radio.Button onClick={() => handleSelect(option.value)} checked={selectedValues.indexOf(option.value) !== -1} key={option._id} className={'form-option–radio-buttons'} value={option.value}>{option.label}</Radio.Button>
                                )) : null
                            }
                            {
                                !_.isEmpty(extraOptions) ? extraOptions.map((option, index) => (
                                    <Radio.Button onClick={() => handleSelect(option.value)} checked={selectedValues.indexOf(option.value) !== -1} key={option._id} className={'form-option–radio-buttons'} value={option.value}>{option.label}</Radio.Button>
                                )) : null
                            }
                        </Space>
                    </div>
                </Form.Item>
                <EnterCaptureInput onEnter={(text) => handleAddExtraOptions(text)} style={{width: '300px', display: 'block', margin: 'auto'}} placeholder={'Add Others'} />
                <Row className="member-edit-form-general-buttons-wrapper">
                    <Col span={6} offset={6}>
                        <Button
                            onClick={handleCancelForm}
                            className="form-general-prev-button"
                        >
                            {currentStep === 1 ? 'Cancel' : "Back"}
                        </Button>
                    </Col>
                    <Col offset={1} span={6}>
                        <Button
                            onClick={handleSubmitForm}
                            className="form-general-next-button"
                        >
                            {currentStep === totalSteps ? 'Save & Proceed' : "Next Step"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
