import React from "react";

import {
  Layout,
  Space,
  Avatar,
  Typography,
  Input,
  ConfigProvider,
  Row,
  Col,
  Spin,
  Button,
  Menu,
  Popover,
} from "antd";
import {
  UserOutlined,
  PhoneFilled,
  VideoCameraFilled,
  ClockCircleFilled,
  PaperClipOutlined,
  AudioTwoTone,
  LoadingOutlined,
  AudioOutlined,
  StopOutlined,
  FileImageFilled,
} from "@ant-design/icons";
import { ContactCardMsg, ImgMsg, TextMsg, VideoMsg } from "./MessageTypes";

import { useDispatch, useSelector } from "react-redux";

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const AudioMsg = ({ el, menu }) => {
  return (
    <Row justify={el.incoming ? "start" : "end"} gutter={[8, 8]}>
      <Col flex="auto">
        <div
          style={{
            backgroundColor: el.incoming ? "#f0f0f0" : "#1890ff",
            padding: "8px",
            borderRadius: "8px",
            maxWidth: "200px",
          }}
        >
          <audio controls>
            <source src={el.audioSrc} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <Text
          style={{
            color: el.incoming ? "black" : "white",
            marginTop: "4px",
            display: "block",
          }}
        >
          {el.message}
        </Text>
      </Col>
    </Row>
  );
};

const RecordingAudioMsg = ({ el, menu }) => {
  return (
    <Row justify={el.incoming ? "start" : "end"} gutter={[8, 8]}>
      <Col flex="auto">
        <div
          style={{
            backgroundColor: el.incoming ? "#f0f0f0" : "#1890ff",
            padding: "8px",
            borderRadius: "8px",
            maxWidth: "200px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {el.isRecording ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
            />
          ) : (
            <AudioOutlined
              style={{ fontSize: 20, color: el.incoming ? "black" : "white" }}
            />
          )}
          <Text
            style={{
              color: el.incoming ? "black" : "white",
              marginLeft: "8px",
              display: "block",
            }}
          >
            {el.isRecording ? "Recording..." : "Recording Audio"}
          </Text>
        </div>
        <Text
          style={{
            color: el.incoming ? "black" : "white",
            marginTop: "4px",
            display: "block",
          }}
        >
          {el.message}
        </Text>
        {el.isRecording && (
          <Button
            type="danger"
            size="small"
            shape="circle"
            icon={<StopOutlined />}
          />
        )}
      </Col>
    </Row>
  );
};

const AttachmentsMenuHeadindStyles = {
  fontFamily: "Noto Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "19px",
  letterSpacing: "0em",
  color: "#1F1F1F",
};

const AttachmentsMenu = (
  <div style={{ width: "180px" }}>
    <Menu>
      <Menu.Item
        key="images"
        style={{ height: "38px", display: "flex", alignItems: "center" }}
      >
        <Space size={10}>
          <FileImageFilled />
          <Text style={AttachmentsMenuHeadindStyles}>Images</Text>
        </Space>
      </Menu.Item>
      <Menu.Item
        key="video"
        style={{ height: "38px", display: "flex", alignItems: "center" }}
      >
        <Space size={10}>
          <FileImageFilled />
          <Text style={AttachmentsMenuHeadindStyles}>Videos</Text>
        </Space>
      </Menu.Item>
      <Menu.Item
        key="doc"
        style={{ height: "38px", display: "flex", alignItems: "center" }}
      >
        <Space size={10}>
          <FileImageFilled />
          <Text style={AttachmentsMenuHeadindStyles}>Document</Text>
        </Space>
      </Menu.Item>
      <Menu.Item
        key="contact"
        style={{ height: "38px", display: "flex", alignItems: "center" }}
      >
        <Space size={10}>
          <FileImageFilled />
          <Text style={AttachmentsMenuHeadindStyles}>Contact</Text>
        </Space>
      </Menu.Item>
      <Menu.Item
        key="poll"
        style={{ height: "38px", display: "flex", alignItems: "center" }}
      >
        <Space size={10}>
          <FileImageFilled />
          <Text style={AttachmentsMenuHeadindStyles}>Poll</Text>
        </Space>
      </Menu.Item>
    </Menu>
  </div>
);

const ConversationBlock = () => {

  const dispatch = useDispatch();
  const chatState = useSelector((state) => state.chatReducer);

  return (
    <div>
      <Layout>
        <Header style={{ background: "#F9F9F9", height: "100%", padding: "0" }}>
          <div className="chat-main-area-header-wrapper">
            <Space size={16}>
              <Avatar size="large" icon={<UserOutlined />} />
              <div className="chat-main-area-header-name-wrapper">
                <Text className="chat-main-area-header-name">David Moore</Text>
                <Text className="chat-main-area-header-tagline">
                  Last seen 5 mins ago
                </Text>
              </div>
            </Space>
            <Space size={30}>
              <PhoneFilled style={{ fontSize: "18px" }} rotate={100} />
              <VideoCameraFilled style={{ fontSize: "18px" }} />
              <ClockCircleFilled style={{ fontSize: "18px" }} />
            </Space>
          </div>
        </Header>
        <Content
          style={{
            background: "#EDF3FF",
            overflow: "auto",
            height: "62vh",
          }}
          className="hideScroll"
        >
          {
            chatState.messages.map((message) => {
              let b = message.is_bot_message ? false : message.source === 'archinzabot' ? false : true;
              return <TextMsg el={{ incoming : b }} messageData = {message}  />
            })
          }
          {/* <AudioMsg el={{ incoming: true }} />
          <RecordingAudioMsg el={{ incoming: true, isRecording: true }} />
          <TextMsg el={{ incoming: true }} />
          <TextMsg el={{}} />
          <ImgMsg el={{ incoming: true }} />
          <ImgMsg el={{}} />
          <VideoMsg el={{}} />
          <ContactCardMsg el={{}} /> */}
        </Content>
        <Footer
          style={{
            background: "#EDF3FF",
            width: "50vw",
            padding: "30px 24px",
            position: "fixed",
            bottom: "0",
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                colorBorder: "#fff",
                colorText: "#1F1F1F",
                controlHeight: "56",
              },
            }}
          >
            <Input
              size="large"
              placeholder="Type Message"
              prefix={
                <Popover
                  placement="bottomRight"
                  content={AttachmentsMenu}
                  trigger="click"
                >
                  <PaperClipOutlined
                    rotate={135}
                    style={{
                      fontSize: "20px",
                      color: "#1F1F1F",
                      marginRight: "20px",
                    }}
                  />
                </Popover>
              }
              suffix={<AudioTwoTone />}
              style={{ padding: "16px" }}
            />
          </ConfigProvider>
        </Footer>
      </Layout>
    </div>
  );
};

export default ConversationBlock;
