import React, { useEffect, useState } from "react";
import {
  Switch,
  Drawer,
  Space,
  Card,
  Carousel,
  Button,
  Rate,
  Input,
  Tabs
} from "antd";

import { CloseOutlined, StarFilled } from "@ant-design/icons";

import "./index.css";
import TagMemberList from "../TagMemberList";
import { ReactComponent as AccountIcon } from "../../../../components/Icon/account.svg";
import { callApiV2 } from "../../../../util/apiCaller";

const { TextArea } = Input;

function footer() {
  return (
    <div className="member-card-footer">
      <Space size="middle" >
        <Button className="member-card-footer-button1">Go to URL</Button>
        <Button className="member-card-footer-button2">View Portfolio</Button>
      </Space>
    </div>
  );
}

function MemberCard({ memberCardOpen, setMemberCardState, memberObject }) {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandsUI, setBrandUI] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});

  useEffect(() => {
  
    setLoading(true);
    //if(memberObject?.id){
    async  function memberData(memberObject) {
      if(memberObject?._id){
        console.log(memberObject?.id);
      try{
    const res = await callApiV2({endpoint: `customer/${memberObject?._id}/profile/info`})
     
      
      configureBrandUI(res?.data?.brands);
      if (res?.data?.brands?.length > 0) {
        setBrands(res?.data?.brands);
        setSelectedBrand(res?.data?.brands)
        setLoading(false);
      }
     
    } catch(data){

    }
  }
  }
  memberData(memberObject)
  //}
  }, [memberObject]);

  const configureBrandUI = (brands) => {
    const items = [];

    for (let i = 0; i < brands.length; i++) {
      const e = brands[i];
      items.push({
        key: e._id,
        label: e.name,
        children: singleBrandUI(e),
      })
    }
    setBrandUI(items);
  }

  const singleBrandUI = (brand) => {
    console.log(brand);
    return (
      <>
        <Card className="member-card-general-card" bodyStyle={{ padding: "0" }}>
          <div className="member-card-general-card-heading-wrapper">
            <AccountIcon
              className="member-card-general-card-heading-icon"
              stroke="#1F1F1F"
            />
            <h1 className="member-card-general-card-heading">{brand?.business_name}</h1>
          </div>

          <div className="member-card-general-block">
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Project Budget
              </h1>
              <p className="member-card-general-block-info-body">
                {brand?.project_budget}
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Project Size
              </h1>
              <p className="member-card-general-block-info-body">
                {brand?.project_size}
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Project Typology
              </h1>
              {brand?.project_typology?.map((item,i) =>(
              <p className="member-card-general-block-info-body" key={i}>
                {item}
              </p>
              ))}
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Business Design Owner
              </h1>
              {brand?.designer_owner?.map((item,i) =>(
                <React.Fragment key={i}>
              <p className="member-card-general-block-info-body" >
                {item?.name}
              </p>
               <p className="member-card-general-block-info-body" >
               {item?.email}
             </p>
             <p className="member-card-general-block-info-body" >
               {item?.highest_qualification}
             </p>
             </React.Fragment>
              ))}
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Firm Design Style
              </h1>
              <p className="member-card-general-block-info-body">
              {brand?.firm_design_style}
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                GST Number
              </h1>
              <p className="member-card-general-block-info-body">
                {brand?.gstin_number}
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Business Categories
              </h1>
              {brand?.business_category?.map((item,i) => (
              <Space size="large" key={i}>
                <p className="member-card-general-block-info-body">
                  {item}
                </p>
              </Space>
              ))}
            </div>
          </div>
        </Card>
        <Card className="member-card-general-card" bodyStyle={{ padding: "0" }}>
          <div className="member-card-general-card-heading-wrapper">
            <AccountIcon
              className="member-card-general-card-heading-icon"
              stroke="#1F1F1F"
            />
            <h1 className="member-card-general-card-heading">INSIGHTS</h1>
          </div>

          <div className="member-card-general-block">
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Contact Number
              </h1>
              <p className="member-card-general-block-info-body">
                +91 - 886 5342 901
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Contact Number
              </h1>
              <p className="member-card-general-block-info-body">
                +91 - 886 5342 901
              </p>
            </div>
          </div>
        </Card>
        <Card className="member-card-general-card" bodyStyle={{ padding: "0" }}>
          <div className="member-card-general-card-heading-wrapper">
            <AccountIcon
              className="member-card-general-card-heading-icon"
              stroke="#1F1F1F"
            />
            <h1 className="member-card-general-card-heading">REVIEWS</h1>
          </div>

          <Carousel autoplay dots={false}>
            <div className="member-card-review-block">
              <div className="member-card-review-block-header">
                <h1 className="member-card-review-block-header-name">
                  Abhi Rawat, Customer
                </h1>{" "}
                <span style={{ margin: "0px 10px" }}>|</span>
                <div className="member-card-review-block-header-rating">
                  <StarFilled style={{ color: "#F5BE19" }} />
                  <span>4.0 Overall Rating</span>
                </div>
              </div>
              <Space size="large" className="member-card-review-block-rating">
                <Space>
                  <StarFilled style={{ color: "#B9D6FF" }} />
                  <span>4.0 Design</span>
                </Space>
                <Space>
                  <StarFilled style={{ color: "#B9D6FF" }} />
                  <span>4.0 Service</span>
                </Space>
                <Space>
                  <StarFilled style={{ color: "#B9D6FF" }} />
                  <span>4.0 Delivery</span>
                </Space>
              </Space>
              <div className="member-card-review-block-description">
                “Sit tempus volutpat non aliquet nibh at lacinia. Viverra ut
                etiam enim habitasse varius”
              </div>
            </div>
            <div className="member-card-review-block">
              <div className="member-card-review-block-header">
                <h1 className="member-card-review-block-header-name">
                  Ritwik, Customer
                </h1>{" "}
                <span style={{ margin: "0px 10px" }}>|</span>
                <div className="member-card-review-block-header-rating">
                  <StarFilled style={{ color: "#F5BE19" }} />
                  <span>4.0 Overall Rating</span>
                </div>
              </div>
              <Space size="large" className="member-card-review-block-rating">
                <Space>
                  <StarFilled style={{ color: "#B9D6FF" }} />
                  <span>4.0 Design</span>
                </Space>
                <Space>
                  <StarFilled style={{ color: "#B9D6FF" }} />
                  <span>4.0 Service</span>
                </Space>
                <Space>
                  <StarFilled style={{ color: "#B9D6FF" }} />
                  <span>4.0 Delivery</span>
                </Space>
              </Space>
              <div className="member-card-review-block-description">
                “Sit tempus volutpat non aliquet nibh at lacinia. Viverra ut
                etiam enim habitasse varius”
              </div>
            </div>
          </Carousel>
        </Card>
        <Card className="member-card-general-card" bodyStyle={{ padding: "0" }}>
          <div className="member-card-general-card-heading-wrapper">
            <AccountIcon
              className="member-card-general-card-heading-icon"
              stroke="#1F1F1F"
            />
            <h1 className="member-card-general-card-heading">WRITE REVIEW</h1>
          </div>

          <Space
            direction="vertical"
            className="member-card-give-review-wrapper"
          >
            <Space
              direction="vertical"
              size="small"
              className="member-card-give-review-block"
              style={{ marginTop: "30px" }}
            >
              <h1 className="member-card-give-review-heading">
                Give Overall Rating
              </h1>
              <Rate />
            </Space>
            <Space
              direction="vertical"
              size="small"
              className="member-card-give-review-block"
            >
              <h1 className="member-card-give-review-heading">
                Give Design Rating
              </h1>
              <Rate />
            </Space>
            <Space
              direction="vertical"
              size="small"
              className="member-card-give-review-block"
            >
              <h1 className="member-card-give-review-heading">
                Give Service Rating
              </h1>
              <Rate />
            </Space>
            <Space
              direction="vertical"
              size="small"
              className="member-card-give-review-block"
            >
              <h1 className="member-card-give-review-heading">
                Give Delivery Rating
              </h1>
              <Rate />
            </Space>
            <TextArea
              rows={4}
              placeholder="Type your review here..."
              bordered={false}
              style={{
                background: "#fff",
                padding: "10px 16px",
                marginTop: "12px",
              }}
            />
            <Button type="text" className="member-card-give-review-save-button">
              SAVE
            </Button>
          </Space>
        </Card>
      </>
    )
  }

  const onBrandChange = (key) => {
    const brand = brands?.filter(e => e._id === key)
    setSelectedBrand(brand ?? {})
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const onClose = () => {
    setMemberCardState([false, memberObject]);
  };


  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        open={memberCardOpen}
        contentWrapperStyle={{ width: "560px" }}
        bodyStyle={{ padding: "40px" }}
        headerStyle={{
          border: "none",
          display: "flex",
          justifyContent: "flex-end",
        }}
        closeIcon={<CloseOutlined className="member-card-close-button" />}
        footer={footer()}
      >
        <Space direction="vertical">
          <Space direction="horizontal">
            <h1 className="member-card-heading">{memberObject.contact_name}</h1>
            <Switch
              defaultChecked
              onChange={onChange}
              className="member-card-toggle"
            />
          </Space>

          <TagMemberList
            style={{ marginLeft: "-4px" }}
            value={memberObject.user_type}
          />
        </Space>
        <Card className="member-card-general-card" bodyStyle={{ padding: "0" }}>
          <div className="member-card-general-card-heading-wrapper">
            <AccountIcon
              className="member-card-general-card-heading-icon"
              stroke="#1F1F1F"
            />
            <h1 className="member-card-general-card-heading">BASIC DETAILS</h1>
          </div>

          <div className="member-card-general-block">
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Contact Name
              </h1>
              <p className="member-card-general-block-info-body">
                {memberObject?.contact_name}
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Contact Number
              </h1>
              <p className="member-card-general-block-info-body">
                {memberObject?.contact_phone}
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                Contact Email
              </h1>
              <p className="member-card-general-block-info-body">
                {memberObject?.contact_email}
              </p>
            </div>
            <div className="member-card-general-block-info">
              <h1 className="member-card-general-block-info-heading">
                User Type
              </h1>
              <p className="member-card-general-block-info-body">
                {memberObject?.user_type}
              </p>
            </div>
          </div>
        </Card>
        <Tabs defaultActiveKey={selectedBrand?._id} style={{ marginTop: "30px" }} items={brandsUI} onChange={onBrandChange} />
      </Drawer>
    </>
  );
}

export default MemberCard;
