import React, { useEffect } from "react";
import "./SideChats.css";

import {
  Space,
  Avatar,
  Typography,
  Input,
  ConfigProvider,
  Popover,
  Menu
} from "antd";
import { MoreOutlined, UserOutlined, SearchOutlined } from "@ant-design/icons";
import ChatItem from "./ChatItem";

import { useDispatch, useSelector } from "react-redux";
import { setActiveUserID, setGroups, setMainArea, setSideBar } from "../../chatSlice";
import {callApi} from "../../../../util/apiCallerV2";

const { Text } = Typography;


const SideChats = () => {

  const dispatch = useDispatch();
  const chatState = useSelector((state) => state.chatReducer);

  const handleCreateBroadcastList= () =>{
    dispatch(setSideBar('CREATE_BROADCAST_LIST'))
  }

  const handleChatItemClick = (data) => {
    dispatch(setActiveUserID(data.customer_id))
    dispatch(setMainArea('CHAT_AREA'))
  }

  const SideChat3DotsMenu = (
    <div style={{ width: "180px" }}>
      <Menu>
        <Menu.Item key="create-group">Create group</Menu.Item>
        <Menu.Item key="create-broadcast" onClick={handleCreateBroadcastList} >Create Broadcast List</Menu.Item>
        <Menu.Item key="schedule-messages">Scheduled Messages</Menu.Item>
      </Menu>
    </div>
  );

  const requestGroupsData = {
    "pageNum": 1,
    "pageSize": 10,
    "filters": {}
}

  useEffect(() => {
    callApi('group/list' ,"post", requestGroupsData).then((data) => {
      dispatch(setGroups(data?.data?.groupList))
    });
  } , []);

  return (
    <div className="side-chats-wrapper">
      <Space className="side-chats-header">
        <Space size={16}>
          <Avatar size="large" icon={<UserOutlined />} />
          <div className="side-chats-header-name-wrapper">
            <Text className="side-chats-header-name">Shruthi Singh</Text>
            <Text className="side-chats-header-name-you">You</Text>
          </div>
        </Space>
        <Popover
          placement="bottomRight"
          content={SideChat3DotsMenu}
          trigger="click"
        >
          <Space align="end" style={{ cursor: "pointer" }}>
            <MoreOutlined
              width="2em"
              style={{ color: "#1F1F1F", fontSize: "20px" }}
            />
          </Space>
        </Popover>
      </Space>
      <div className="side-chats-search-box">
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#F5F5F5",
              colorBorder: "#F5F5F5",
            },
          }}
        >
          <Input
            size="large"
            placeholder="Search"
            prefix={<SearchOutlined style={{ marginRight: "19px" }} />}
          />
        </ConfigProvider>
      </div>
      <div className="side-chats-chats-section">
        {
          chatState?.groups?.map((data) => {
            return <ChatItem data = {data} key={data?._id} onClick={() => {handleChatItemClick(data)}} />
          })
        }
      </div>
    </div>
  );
};

export default SideChats;
