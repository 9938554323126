import React from "react";

import { Space, Typography, Avatar } from "antd";
import { CheckOutlined, UserOutlined } from "@ant-design/icons";

import "./MessageTypes.css";

const { Text } = Typography;

const TextMsg = ({ el , messageData }) => {

  const timestamp = new Date(messageData.created_at);
  const message_hours = timestamp
    .getUTCHours()
    .toString()
    .padStart(2, "0");
  const message_minutes = timestamp
    .getUTCMinutes()
    .toString()
    .padStart(2, "0");
  const PM = timestamp
  .getUTCHours() >= 12 ? true : false;

  return (
    <div
      className="general-message-type-wrapper"
      style={{ justifyContent: el.incoming ? "flex-start" : "flex-end" }}
    >
      <Space
        direction="vertical"
        size={4}
        className="general-message-type-innerwrapper"
        style={{
          background: el.incoming ? "#FFFFFF" : "#1677FF",
        }}
      >
        <Text
          style={{
            color: el.incoming ? "#1F1F1F" : "#FFFFFF",
          }}
          className="general-message-type-message"
        >
          {messageData.text}
        </Text>
        <Space style={{ float: "right" }} size={4}>
          <Text
            style={{
              color: el.incoming ? "#1F1F1F" : "#FFFFFF",
            }}
            className="general-message-type-sent-time"
          >
            {`${message_hours}:${message_minutes} ${PM ? 'PM' : 'AM'}`}
          </Text>
          {el.incoming ? null : (
            <CheckOutlined style={{ color: el.seen ? "#FAAD14" : "#fff" }} />
          )}
        </Space>
      </Space>
    </div>
  );
};

const ImgMsg = ({ el }) => {
  return (
    <div
      className="general-message-type-wrapper"
      style={{ justifyContent: el.incoming ? "flex-start" : "flex-end" }}
    >
      <Space
        direction="vertical"
        size={4}
        className="general-message-type-innerwrapper message-type-image-innerwrapper"
        style={{
          background: el.incoming ? "#FFFFFF" : "#1677FF",
        }}
      >
        <img src="./img.png" alt="" style={{ width: "100%" }} />
        <Text
          style={{
            color: el.incoming ? "#1F1F1F" : "#FFFFFF",
          }}
          className="general-message-type-message"
        >
          Rutrum in urna
        </Text>
        <Space style={{ float: "right" }} size={4}>
          <Text
            style={{
              color: el.incoming ? "#1F1F1F" : "#FFFFFF",
            }}
            className="general-message-type-sent-time"
          >
            19:48 PM
          </Text>
          {el.incoming ? null : (
            <CheckOutlined style={{ color: el.seen ? "#FAAD14" : "#fff" }} />
          )}
        </Space>
      </Space>
    </div>
  );
};

const VideoMsg = ({ el }) => {
  return (
    <div
      className="general-message-type-wrapper"
      style={{ justifyContent: el.incoming ? "flex-start" : "flex-end" }}
    >
      <Space
        direction="vertical"
        size={4}
        className="general-message-type-innerwrapper message-type-image-innerwrapper"
        style={{
          background: el.incoming ? "#FFFFFF" : "#1677FF",
        }}
      >
        <video width="100%" controls>
          <source
            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            type="video/mp4"
          />
        </video>
        <Space style={{ float: "right" }} size={4}>
          <Text
            style={{
              color: el.incoming ? "#1F1F1F" : "#FFFFFF",
            }}
            className="general-message-type-sent-time"
          >
            19:48 PM
          </Text>
          {el.incoming ? null : (
            <CheckOutlined style={{ color: el.seen ? "#FAAD14" : "#fff" }} />
          )}
        </Space>
      </Space>
    </div>
  );
};

const ContactCardMsg = ({ el }) => {
  return (
    <div
      className="general-message-type-wrapper"
      style={{ justifyContent: el.incoming ? "flex-start" : "flex-end" }}
    >
      <Space
        direction="vertical"
        size={4}
        className="general-message-type-innerwrapper message-type-image-innerwrapper"
        style={{
          background: el.incoming ? "#FFFFFF" : "#1677FF",
        }}
      >
        <Space
          style={{
            background: "#fff",
            width: "100%",
            borderRadius: "6px",
            padding: "6px 12px",
          }}
        >
          <Space>
            <Avatar size="medium" icon={<UserOutlined />} />
          </Space>
          <Space direction="vertical" size={0}>
            <Text
              style={{
                color: el.incoming ? "#1F1F1F" : "#8C8C8C",
                fontSize: "14px",
              }}
              className="general-message-type-message"
            >
              Ami James
            </Text>
            <Text
              style={{
                color: el.incoming ? "#1F1F1F" : "#000",
                fontSize: "14px",
              }}
              className="general-message-type-message"
            >
              +91 99119 28288
            </Text>
          </Space>
        </Space>

        <Space style={{ float: "right" }} size={4}>
          <Text
            style={{
              color: el.incoming ? "#1F1F1F" : "#FFFFFF",
            }}
            className="general-message-type-sent-time"
          >
            19:48 PM
          </Text>
          {el.incoming ? null : (
            <CheckOutlined style={{ color: el.seen ? "#FAAD14" : "#fff" }} />
          )}
        </Space>
      </Space>
    </div>
  );
};

export { TextMsg, ImgMsg, VideoMsg, ContactCardMsg };
