import { Space, Typography, Collapse, ConfigProvider } from "antd";
import { RightOutlined } from "@ant-design/icons";
import React from "react";

import "./ChatProfile.css";

const { Text } = Typography;

const details = [
  {
    key: 1,
    heading: "Status",
    body: "Initiated",
  },
  {
    key: 2,
    heading: "Last Active",
    body: "16 July, 10:19 PM",
  },
  {
    key : 3,
    heading : 'Template Message',
    body : 'NA'
  },
  {
    key: 4,
    heading: "Session Message",
    body: "2",
  },
  {
    key: 5,
    heading: "Source",
    body: "NA",
  },
  {
    key: 6,
    heading: "Lead State",
    body: "Initiated",
  },
  {
    key: 7,
    heading: "First Message",
    body: "NA",
  },
  {
    key: 8,
    heading: "Contact",
    body: "8851218699",
  },
  {
    key: 9,
    heading: "Email",
    body: "shrutisingh@gmail.com",
  },
  {
    key: 10,
    heading: "WhatsApp Opted",
    body: "No",
  },
];

const getItems = (panelStyle) => [
  {
    key: "1",
    label: <Text className="chat-profile-dropdown-heading">Details</Text>,
    children: (
      <div>
        <Space direction="vertical" style={{width : '100%'}} size={14} >
          {details.map((d) => {
            return (
              <div className="chat-profile-dropdown-inner-block" key={d.key}>
                <Text className="chat-profile-dropdown-inner-block-text1">
                  {d.heading}
                </Text>
                <Text className="chat-profile-dropdown-inner-block-text2">
                  {d.body}
                </Text>
              </div>
            );
          })}
        </Space>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: <Text className="chat-profile-dropdown-heading">Customer Journey</Text>,
    children: <p>{`text`}</p>,
    style: panelStyle,
  },
  {
    key: "3",
    label: <Text className="chat-profile-dropdown-heading">Tags</Text>,
    children: <p>{`text`}</p>,
    style: panelStyle,
  },
];

const ChatProfile = () => {
  var panelStyle = {
    marginBottom: 24,
    borderRadius: "6px",
    background: "#F5F8FF",
    border: "1px solid #F0F0F0",
  };

  return (
    <div className="chat-profile-wrapper">
      <Space direction="vertical" size={4} className="chat-profile-header">
        <Text className="chat-profile-heading">Chat Profile</Text>
        <Text className="chat-profile-body">Check the user details here</Text>
      </Space>
      <ConfigProvider
        theme={{
          token: {},
        }}
      >
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <RightOutlined rotate={isActive ? 90 : 0} />
          )}
          expandIconPosition="end"
          style={{ background: "#fff", padding: "24px" }}
          items={getItems(panelStyle)}
        />
      </ConfigProvider>
    </div>
  );
};

export default ChatProfile;
