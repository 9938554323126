import {combineReducers} from "redux";

import appReducer from './modules/appReducer'
import adminReducer from './modules/Admin/adminReducer'
import chatSlice from './modules/Members/chatSlice'
import memberReducer from "./modules/Members/memberReducer";

export default combineReducers({
    appReducer : appReducer,
    adminReducer,
    memberReducer: memberReducer,
    chatReducer : chatSlice
});
