import {Button, Checkbox, Col, ConfigProvider, Form, Input, Row, Select} from "antd";
import React, {useState} from "react";
import {useNavigate} from "react-router";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createNewMember, handleCreateMember, handleUpdateMember, updateMemberData} from "../../memberAction";
import {callApiV2} from "../../../../util/apiCaller";
import {showNotification} from "../../../../util/Utility";

export default function RegistrationFormStep({nextStep, previousStep, currentStep, totalSteps}) {
    const [data, setData] = useState({});
    const [isWhatsappSame, setWhatsappSame] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [params] = useSearchParams();

    const { Option } = Select;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const {memberId, member} = useSelector(state => state.memberReducer)

    const handleCancelForm = (formData) => {
        previousStep();
    }

    const handleSubmitForm = async (formData) => {
        let customer = {
            user_type: params.get('member_type'),
            contact_name: formData.contact_name,
            contact_email: formData.contact_email,
            password: formData.password,
            country_code: formData.country_code,
            contact_phone: formData.contact_phone,
            whatsapp_no: isWhatsappSame ? formData.contact_phone : formData.whatsapp_no,
        }

        if(memberId && memberId !== '') {
           let res = await handleUpdateMember(customer, memberId);
            if(res.status === 'Success') {
                dispatch(updateMemberData(res.data.customer));
                nextStep();
            } else {
                showNotification('error', res?.data?.message ? res.data.message : 'Server Error')
            }
        } else {
            let res = await handleCreateMember(customer);

            if(res.status === 'Success') {
                dispatch(createNewMember(res.data.customer));
                nextStep();
            } else {
                showNotification('error', res?.data?.message ? res.data.message : 'Server Error')
            }
        }
    }

    return (
        <Form
            className="member-edit-general-form"
            name="Basic Details"
            layout="vertical"
            labelCol={{
                span: 16,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={data}
            onFinish={handleSubmitForm}
            autoComplete={'off'}
        >
            <Row gutter={40}>
                <Col span={12}>
                    <Form.Item
                        label={
                            <h1 className="member-edit-form-general-label">Name</h1>
                        }
                        name="contact_name"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your name!",
                            },
                        ]}
                    >
                        <Input className="member-edit-form-general-input-box" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={
                            <h1 className="member-edit-form-general-label">Email</h1>
                        }
                        name="contact_email"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your email!",
                            },
                        ]}
                    >
                        <Input type={'email'} className="member-edit-form-general-input-box" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={40}>
                <Col span={12}>
                    <Row>
                        <Col span={4}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Select: {
                                            multipleItemHeight: 60,
                                        },
                                    },
                                    token: {
                                        colorBgContainer: "#f5f8ff",
                                        colorBorder: "#f0f0f0",
                                    },
                                }}
                            >
                                <Form.Item
                                    label={<h1 className="member-edit-form-general-label">Code</h1>}
                                    name="country_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                    ]}
                                >
                                    <Select className="">
                                        <Option value="+91">+91</Option>
                                        <Option value="+161">+161</Option>
                                    </Select>
                                </Form.Item>
                            </ConfigProvider>
                        </Col>
                        <Col offset={1} span={19}>
                            <Form.Item
                                label={<h1 className="member-edit-form-general-label">Mobile No.</h1>}
                                name={"contact_phone"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your phone number with code!",
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="member-edit-form-general-input-box"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={4}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Select: {
                                            multipleItemHeight: 60,
                                        },
                                    },
                                    token: {
                                        colorBgContainer: "#f5f8ff",
                                        colorBorder: "#f0f0f0",
                                    },
                                }}
                            >
                                <Form.Item
                                    label={<h1 className={'member-edit-form-general-label'}>Code</h1>}
                                    name={isWhatsappSame ? "country_code" : "whatsapp_no_country_code"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "",
                                        },
                                    ]}
                                >
                                    <Select className="">
                                        <Option value="+91">+91</Option>
                                        <Option value="+161">+161</Option>
                                    </Select>
                                </Form.Item>
                            </ConfigProvider>
                        </Col>
                        <Col offset={1} span={19}>
                            <Form.Item
                                label={<h1 className={'member-edit-form-general-label'}>Whatsapp no.</h1>}
                                name={isWhatsappSame ? "contact_phone" : "whatsapp_no"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your whatsapp number with code!",
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="member-edit-form-general-input-box"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Checkbox onChange={e => setWhatsappSame(prev => !prev)} className={'member-edit-form-general-label'}>WhatsApp is same as phone</Checkbox>
            <Row style={{marginTop: '24px'}} gutter={40}>
                <Col span={12}>
                    <Form.Item
                        label={
                            <h1 className="member-edit-form-general-label">Password</h1>
                        }
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please enter password!",
                            },
                        ]}
                    >
                        <Input.Password
                            bordered={false}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            visibilityToggle={{
                                visible: passwordVisible,
                                onVisibleChange: setPasswordVisible,
                            }}
                            type={'password'}
                            className="member-edit-form-general-input-box"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        dependencies={['password']}
                        label={
                            <h1 className="member-edit-form-general-label">Confirm Password</h1>
                        }
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input className="member-edit-form-general-input-box" />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="member-edit-form-general-buttons-wrapper">
                <Col span={6} offset={6}>
                    <Button
                        onClick={handleCancelForm}
                        className="member-edit-form-general-prev-button"
                    >
                        {currentStep === 1 ? 'Cancel' : "Back"}
                    </Button>
                </Col>
                <Col offset={1} span={6}>
                    <Button
                        className="member-edit-form-general-next-button"
                        htmlType={'submit'}
                    >
                        {currentStep === totalSteps ? 'Save & Proceed' : "Next Step"}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}
