import React, { useState } from "react";
import {
  Button,
  Progress,
  Form,
  Input,
  Row,
  Col,
  Select,
  ConfigProvider,
  Breadcrumb,
  Upload,
  DatePicker,
  Modal,
  Space,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteFilled,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
const { Option } = Select;

function PortfolioDetails() {
  const onFinish = (values) => {
    console.log("Success Portfolio Details :", values);
    showModal();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  return (
    <div className="member-edit-general-wrapper">
      <Breadcrumb
        items={[
          {
            title: (
              <a href="/members" style={{ color: "#1677FF" }}>
                <ArrowLeftOutlined
                  style={{ marginRight: "10px", width: "15px", height: "10px" }}
                />
                Members
              </a>
            ),
          },
          {
            title: <span style={{ color: "#8C8C8C" }}>Portfolio</span>,
          },
        ]}
        className="member-edit-general-breadcrumb"
      />

      <div className="member-edit-general-form-wrapper">
        <h1 className="member-edit-general-form-heading">
          Enter Portfolio Details
        </h1>
        <div className="member-edit-general-form-steps">
          <span style={{ color: "#FAAD14" }}>Step 4 </span>of 4
        </div>
        <Progress
          percent={100}
          showInfo={false}
          strokeColor="#FAAD14"
          className="member-edit-general-form-progress-bar"
        />
        <Form
          className="member-edit-general-form"
          name="Basic Details"
          layout="vertical"
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.List
            name="portfolio_details"
            initialValue={[{}]}
            rules={[
              {
                validator: async (_, reviews) => {
                  if (!reviews || reviews.length < 1) {
                    return Promise.reject(
                      new Error("At least one category is required.")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <div key={`portfolio${index}`}>
                    <h1 className="member-edit-general-form-heading">
                      {index + 1}. Project
                    </h1>
                    {fields.length > 1 ? (
                      <div
                        className="member-edit-general-form-remove-icon-wrapper"
                        onClick={() => remove(field.name)}
                      >
                        <Space>
                          <p className="member-edit-general-form-remove-icon-delete">
                            Delete
                          </p>
                          <DeleteFilled style={{ color: "#FF0000" }} />
                        </Space>
                      </div>
                    ) : null}
                    <Row gutter={40} style={{ marginTop: "30px" }}>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              Project Name
                            </h1>
                          }
                          name={[field.name, "project_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your project name!",
                            },
                          ]}
                        >
                          <Input className="member-edit-form-general-input-box" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Document (Optional)
                              </h1>
                            }
                            name={[field.name, "file"]}
                          >
                            <Upload
                              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                              listType="text"
                            >
                              <Button
                                icon={<UploadOutlined />}
                                style={{ height: "57px", marginTop: "-5px" }}
                              >
                                Upload
                              </Button>
                            </Upload>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: "30px" }}>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              Explain about project (200-500 words)
                            </h1>
                          }
                          name={[field.name, "about_project"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter about the project",
                            },
                          ]}
                        >
                          <Input.TextArea
                            className="member-edit-form-general-input-box"
                            maxLength={500}
                            style={{ height: 120, resize: "none" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: "30px" }}>
                      <Col span={6}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              Project Tags
                            </h1>
                          }
                          name={[field.name, "project_tags"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your project tags!",
                            },
                          ]}
                        >
                          <Input className="member-edit-form-general-input-box" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              Client Name
                            </h1>
                          }
                          name={[field.name, "client_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter client name!",
                            },
                          ]}
                        >
                          <Input className="member-edit-form-general-input-box" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                multipleItemHeight: 60,
                              },
                            },
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Location
                              </h1>
                            }
                            name={[field.name, "location"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select location",
                              },
                            ]}
                          >
                            {/* Update the Select component */}
                            <Select className="member-edit-form-general-select">
                              <Option value="delhi">Delhi , India</Option>
                              <Option value="hyderabad">
                                Hyderabad , India
                              </Option>

                              {/* Correct the typo here */}
                            </Select>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                      <Col span={6}>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Duration
                              </h1>
                            }
                            name={[field.name, "duration"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select duration",
                              },
                            ]}
                          >
                            <DatePicker.RangePicker
                              picker="month"
                              style={{ height: "57px", marginTop: "-5px" }}
                            />
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: "30px" }}>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              Add Section Title
                            </h1>
                          }
                          name={[field.name, "section_title"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the section title!",
                            },
                          ]}
                        >
                          <Input className="member-edit-form-general-input-box" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorBgContainer: "#f5f8ff",
                              colorBorder: "#f0f0f0",
                            },
                          }}
                        >
                          <Form.Item
                            label={
                              <h1 className="member-edit-form-general-label">
                                Section Image (Optional)
                              </h1>
                            }
                            name={[field.name, "section_image"]}
                          >
                            <Upload
                              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                              listType="picture"
                            >
                              <Button
                                icon={<UploadOutlined />}
                                style={{ height: "57px", marginTop: "-5px" }}
                              >
                                Upload
                              </Button>
                            </Upload>
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                    </Row>
                    <Row gutter={40} style={{ marginTop: "30px" }}>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <h1 className="member-edit-form-general-label">
                              Explain about this section
                            </h1>
                          }
                          name={[field.name, "about_section"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter about the section",
                            },
                          ]}
                        >
                          <Input.TextArea
                            className="member-edit-form-general-input-box"
                            maxLength={500}
                            style={{ height: 120, resize: "none" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    className="member-edit-form-general-add-field-button member-edit-form-review-details-add-field-button"
                    icon={<PlusOutlined />}
                  >
                    ADD ANOTHER REVIEW
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item></Form.Item>
          <Row className="member-edit-form-general-buttons-wrapper">
            <Col span={6} offset={6}>
              <Button
                className="member-edit-form-general-prev-button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Col>
            <Col offset={1} span={6}>
              <Button
                className="member-edit-form-general-next-button"
                htmlType="submit"
                onClick={onFinish}
              >
                Save & Proceed
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          footer={[]}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          width={400}
          closeIcon={null}
        >
          <div className="member-added-sucess-modal">
            <div className="member-added-sucess-modal-box1"></div>
            <div className="member-added-sucess-modal-box2">
              <h1>Member Added</h1>
              <p>New Member is added. Keep doing the great work!</p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default PortfolioDetails;
