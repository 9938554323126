import React, { useEffect, useState } from "react";
import {
  Button,
  Progress,
  Form,
  Input,
  Row,
  Col,
  Select,
  ConfigProvider,
  Breadcrumb,
} from "antd";
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useParams ,useNavigate} from "react-router";
import { callApi } from "../../../../util/apiCallerV2";
const { Option } = Select;

function BrandDetails() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const {brandId} = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    callApi(`customer/${brandId}/profile/info`).then((data) => {
      setData(data.data.customer);
      setLoading(false);
    });
  }, [brandId]);

  const saveData = async (values) => {
    console.log("Success Basic Details :", values);
    navigate(-1);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="member-edit-general-wrapper">
      <Breadcrumb
        items={[
          {
            title: (
              <a href="/members" style={{ color: "#1677FF" }}>
                <ArrowLeftOutlined
                  style={{ marginRight: "10px", width: "15px", height: "10px" }}
                />
                Members
              </a>
            ),
          },
          {
            title: <span style={{ color: "#8C8C8C" }}>Brand Details</span>,
          },
        ]}
        className="member-edit-general-breadcrumb"
      />

      <div className="member-edit-general-form-wrapper">
        <h1 className="member-edit-general-form-heading">
          Enter Brand Details
        </h1>
        <div className="member-edit-general-form-steps">
          <span style={{ color: "#FAAD14" }}>Step 2 </span>of 4
        </div>
        <Progress
          percent={50}
          showInfo={false}
          strokeColor="#FAAD14"
          className="member-edit-general-form-progress-bar"
        />
        {!loading && <Form
          className="member-edit-general-form"
          name="Basic Details"
          layout="vertical"
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={data}
          onFinish={saveData}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item
                label={
                  <h1 className="member-edit-form-general-label">Brand Name</h1>
                }
                name="brand_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your brand name!",
                  },
                ]}
              >
                <Input className="member-edit-form-general-input-box" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <h1
                className="member-edit-form-general-label"
                style={{ paddingBottom: "8px" }}
              >
                Business No.
              </h1>
              <Row>
                <Col span={4}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          multipleItemHeight: 60,
                        },
                      },
                      token: {
                        colorBgContainer: "#f5f8ff",
                        colorBorder: "#f0f0f0",
                      },
                    }}
                  >
                    <Form.Item
                      name="business_no_country_code"
                      rules={[
                        {
                          required: true,
                          message: "Please select country code!",
                        },
                      ]}
                    >
                      <Select className="">
                        <Option value="+91">+91</Option>
                        <Option value="+161">+161</Option>
                      </Select>
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col offset={1} span={19}>
                  <Form.Item
                    name="business_no"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your business number!",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      className="member-edit-form-general-input-box"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <h1
                className="member-edit-form-general-label"
                style={{ paddingBottom: "8px" }}
              >
                WhatsApp No.
              </h1>
              <Row>
                <Col span={4}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          multipleItemHeight: 60,
                        },
                      },
                      token: {
                        colorBgContainer: "#f5f8ff",
                        colorBorder: "#f0f0f0",
                      },
                    }}
                  >
                    <Form.Item
                      name="whatsapp_no_country_code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter select country code!",
                        },
                      ]}
                    >
                      <Select className="">
                        <Option value="+91">+91</Option>
                        <Option value="+161">+161</Option>
                      </Select>
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col offset={1} span={19}>
                  <Form.Item
                    name="whatsapp_no"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your whatsapp number!",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      className="member-edit-form-general-input-box"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <h1 className="member-edit-form-general-label">GSTIN</h1>
                }
                name="gstin"
                rules={[
                  {
                    required: true,
                    message: "Please enter GSTIN",
                  },
                ]}
              >
                <Input className="member-edit-form-general-input-box" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item
                label={<h1 className="member-edit-form-general-label">TIN</h1>}
                name="tin"
                rules={[
                  {
                    required: true,
                    message: "Please enter TIN!",
                  },
                ]}
              >
                <Input className="member-edit-form-general-input-box" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <h1 className="member-edit-form-general-label">
                    Instagram Link
                  </h1>
                }
                name="instagram_link"
              >
                <Input className="member-edit-form-general-input-box" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col span={12}>
              <Form.Item
                label={
                  <h1 className="member-edit-form-general-label">
                    Twitter Link
                  </h1>
                }
                name="twitter_link"
              >
                <Input className="member-edit-form-general-input-box" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <h1 className="member-edit-form-general-label">URL Link</h1>
                }
                name="url_link"
              >
                <Input className="member-edit-form-general-input-box" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Form.List
              name="product_categories"
              initialValue={[""]}
              rules={[
                {
                  validator: async (_, reviews) => {
                    if (!reviews || reviews.length < 1) {
                      return Promise.reject(
                        new Error("At least one category is required.")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Col span={12} key={`Col${index}`}>
                      <Form.Item
                        label={
                          <h1 className="member-edit-form-general-label">
                            Product Category {index + 1}
                          </h1>
                        }
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input className="member-edit-form-general-input-box" />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    </Col>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      className="member-edit-form-general-add-field-button"
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item></Form.Item>
          </Row>
          <Row className="member-edit-form-general-buttons-wrapper">
            <Col span={6} offset={6}>
              <Button
                className="member-edit-form-general-prev-button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Col>
            <Col offset={1} span={6}>
              <Button
                className="member-edit-form-general-next-button"
                htmlType="submit"
              >
                Save & Proceed
              </Button>
            </Col>
          </Row>
        </Form>}
      </div>
    </div>
  );
}

export default BrandDetails;
