import React, { useEffect } from "react";
import { Menu, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useSearchParams } from "react-router";
import { useDispatch } from "react-redux";
import { logoutHandler } from "../../../Admin/adminAction";
import { LOG_OUT } from "../../../../constants/appEnums";

const RightMenu = ({ mode }) => {
  // const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation()
  let path = location.pathname
  const dispatch = useDispatch()
  

  const onClick = (e) => {
    if(`${e.key}` === LOG_OUT){
      dispatch(logoutHandler())
    }else{
    navigate(`${e.key}`)
    }
  };

  return (
    <div className="nav-menu">
      <Menu mode={mode} onClick={onClick} 
      defaultOpenKeys={["members"]}
      defaultSelectedKeys={[path.substring(1)]}
      >
        <Menu.Item key="chat">Chat</Menu.Item>
        <Menu.Item key="members">Members</Menu.Item>
        <Menu.Item key="vision-search">Vision Search</Menu.Item>
        <Menu.Item key="jobs">Job</Menu.Item>
        <Menu.SubMenu
          title={
            <>
              <Avatar icon={<UserOutlined />} />
              <span className="username">SS</span>
            </>
          }
          className="nav-profile-menu"
        >
          <Menu.Item key="profile">
            My Profile
            {/* <CodeOutlined /> Projects */}
          </Menu.Item>
          <Menu.Item key="more">
            More
            {/* <UserOutlined /> Profile */}
          </Menu.Item>
          <Menu.Item key="log-out" >
            Logout
            {/* <LogoutOutlined /> Logout */}
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </div>
  );
};

export default RightMenu;
