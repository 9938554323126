import React from "react";
import "./CreateBroadcastListSide.css";

import {
  Layout,
  Space,
  Typography,
  Input,
  ConfigProvider,
  Avatar,
  Checkbox,
  Button,
} from "antd";
import {
  ArrowLeftOutlined,
  SearchOutlined,
  UserOutlined,
  NotificationFilled,
  ArrowRightOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { setSideBar , setBroadcastList } from "../../chatSlice";

const { Text } = Typography;

const SideBarComponent = (s) => {
  switch (s) {
    case "SELECT_BROADCAST_LIST":
      return <SelectParticipants />;
    case "BROADCAST_LIST_TITLE":
      return <BroadcastTitle />;
    default:
      break;
  }
};

const SelectParticipants = () => {

  const dispatch = useDispatch();

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  return (
    <div>
      <div className="side-chats-search-box">
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#F5F5F5",
              colorBorder: "#F5F5F5",
            },
          }}
        >
          <Input
            size="large"
            placeholder="Search"
            prefix={<SearchOutlined style={{ marginRight: "19px" }} />}
          />
        </ConfigProvider>
      </div>
      <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
        <div className="chat-item-wrapper" style={{ width: "100%" }}>
          <div className="chat-item-block1">
            <Avatar size="large" icon={<UserOutlined />} />
            <div
              className="chat-item-name-wrapper"
              style={{ justifyContent: "center" }}
            >
              <Text className="chat-item-name">Ryan Curtis</Text>
              <Text className="chat-item-name-message">
                Diam integer tempus
              </Text>
            </div>
          </div>
          <Checkbox value="1" />
        </div>
      </Checkbox.Group>
      <Button disabled={false} className="broadcast-title-proceed" onClick={() => {dispatch(setBroadcastList('BROADCAST_LIST_TITLE'))}}>
        Proceed <ArrowRightOutlined />{" "}
      </Button>
    </div>
  );
};

const BroadcastTitle = () => {
  return (
    <div className="create-broadcast-list-title-wrapper">
      <div className="broadcast-title-icon-wrapper">
        <NotificationFilled style={{ color: "#1677FF", fontSize: "75px" }} />
      </div>
      <Text className="broadcast-title-heading">Broadcast List Name</Text>
      <Input
        placeholder="Type Here"
        className="broadcast-title-input"
        style={{
          width: "200px",
          border: "none",
          borderBottom: "1px solid #1677FF",
          borderRadius: "0",
        }}
      />
      <Button className="broadcast-title-proceed">
        Proceed <ArrowRightOutlined />{" "}
      </Button>
    </div>
  );
};

const CreateBroadcastListSide = () => {
  const dispatch = useDispatch();
  const chatState = useSelector((state) => state.chatReducer);

  return (
    <div className="create-broadcast-list-side-wrapper">
      <Space size={16} className="create-broadcast-list-side-header">
        <ArrowLeftOutlined
          style={{ fontSize: "20px", color: "#1F1F1F", cursor: "pointer" }}
          onClick={() => {
            dispatch(setSideBar("CHATS"));
          }}
        />
        <Space size={4} direction="vertical">
          <Text className="create-broadcast-list-side-heading">
            CREATE BROADCAST LIST
          </Text>
          <Text className="create-broadcast-list-side-tagline">
            Add Participants
          </Text>
        </Space>
      </Space>

      {SideBarComponent(chatState.broadcastList)}
    </div>
  );
};

export default CreateBroadcastListSide;
