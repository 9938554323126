import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import currency from "../../../src/constants/currency.json"
const Selectbox = styled.div`
  width: 80px;
  border-right: 0.5px solid #a0aec063;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;

  .codebox {
    position: absolute;
    height: 20px;
    width: 30px;
    background-color: white;
    padding: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  .flag-img {
    width: 30px;
    height: 16px;
    outline: none;
    position: absolute;
    border-radius: 5px;
    left: 8px;
  }

  select {
    font-family: "Poppins", sans-serif;
    width: 53px;
    height: 20px;
    background: none;
    border: none;
    font-size: 13px;
    font-weight: 300;
    outline: none;
    margin-left: 7px;
  }
`;

const CurrencySymbols = ({ setCurrencySymbols, isDisabled }) => {
  const box = useRef(null);
  const codeBox = useRef(null);

  useEffect(() => {
    codeBox.current.innerText = "" + box.current.options[0].value;
  }, []);

  const [code, setCode] = useState("91");
  //   const [flag, setFlag] = useState("https://flagpedia.net/data/flags/h80/in.webp")

  const handleCurrencyChange = (e) => {
    if (!isDisabled) {
      setCode(e.target.value);
      setCurrencySymbols("" + e.target.value);
      // setFlag(`https://flagpedia.net/data/flags/h80/${e.target.options[e.target.selectedIndex].dataset.countrycode.toLowerCase()}.webp`)

      //getting Option string containing country and code and parsing it to display number
      codeBox.current.innerText =
        "" + box.current.options[e.target.selectedIndex].value;
    }
  };

  return (
    <Selectbox>
      <div ref={codeBox} className="codebox"></div>
      {/* <img className="flag-img" src={flag} alt="flag-image" /> */}
      <select
        ref={box}
        name="country-code"
        id="country_list"
        onChange={handleCurrencyChange}
      >
        {currency?.map(item => (
          <option data-currencysymbols={item.currencysymbols} value={item.value}>
          {item.name}
        </option>
        ))}

      </select>
    </Selectbox>
  );
};

export default CurrencySymbols;
