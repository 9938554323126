import React, {useEffect, useState} from "react";
import {
    Table,
    Input,
    Button,
    ConfigProvider,
    Space,
    Modal,
    Collapse,
    Checkbox,
    Popover,
    Menu, Radio
} from "antd";
import {SearchOutlined, FilterOutlined} from "@ant-design/icons";
import TagMemberList from "../TagMemberList";
import ToggleMemberList from "../ToggleMemberList";

import "./index.css";
import MemberCard from "../MemberCard";
import callPostApi from "../ApiCallers/CallPostApi";
import {useNavigate} from "react-router";
import AddMemberModalSelection from "../../pages/MemberList/AddMemberModalSelection";
import {showNotification} from "../../../../util/Utility";
import {callApi} from "../../../../util/apiCallerV2";
import _ from "lodash";
import { useCallback } from "react";

const AddMemberData = [
    {
        image: '/assets/svg/business.svg',
        title: 'Business'
    },
    {
        image: '/assets/svg/design_enthu.svg',
        title: 'Design Enthusiast'
    },
    {
        image: '/assets/svg/student.svg',
        title: 'Student'
    },
    {
        image: '/assets/svg/team-member.svg',
        title: 'Team Member'
    }
]

const options = [
    {
        label: "Business",
        value: "business",
    },
    {
        label: "Student",
        value: "student",
    },
    {
        label: "Design Enthusiast",
        value: "design-enthusiast",
    },
    {
        label: "Team Member",
        value: "team-member",
    },
];

const countryOptions = [
    {
        label: "India",
        value: "india",
    },
    {
        label: "Dubai",
        value: "dubai",
    },
    {
        label: "USA",
        value: "usa",
    }
];

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

function MemberListTable() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal2Open, setModal2Open] = useState(false);
    const [addMemberModal, setAddMemberModal] = useState(false);
    const [memberType, setMemberType] = useState('')
    const [customerCount, setCustomerCount] = useState(0)
    const [user, setUser] = useState("")
    const [filters, setFilters] = useState({
        user_type: [],
        country: [],
        is_whatsapp_opt_in: ""

    });
    const [memberCardState, setMemberCardState] = useState([false, {}]);

    const handleFilterSelection = (key, value) => {
        let tempFilters = {...filters};

        tempFilters[key] = (value);

        setFilters(tempFilters);
    }

    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 25,
            showSizeChanger: true,
            // position : ['bottomCenter']
        },
    });

  const handleMenuClick = (key, record) => {
    if (key === 'view') {
      setMemberCardState([true, record]);
    } else if (key === 'edit') {
      navigate(`/members/edit/${record?._id}`);
    } else if (key === 'portfolio') {
      navigate(`/members/edit/${record?._id}/portfolio`);
    }

  }

    const debouncedHandleSearch = useCallback(
        _.debounce((qtext) => {
            fetchByName(qtext);
        }, 700),
        []
    );

    const handleSearch = (qtext) => {
        setUser(qtext);
        debouncedHandleSearch(qtext); // This will be called after 700ms of user inactivity
    }



    const fetchByName = (user) => {
        let filters = {};
        if ( user) {
            filters.qtext = user;
        }
        callApi(`customer/list`,'post', {
            filters,
            pageNum: tableParams?.pagination.current,
            pageSize: tableParams?.pagination.pageSize,
        }).then(res => {
            if (res?.status === "Success") {
                setData(res?.data?.customerList);
                setCustomerCount(res?.data?.customerCount);

            } else {
                console.log('err', res?.data?.message);
            }
        });
    };


  const moreMenuContent = (record) => (
    <div style={{ width: "120px" }}>
      <Menu onClick={(e) => handleMenuClick(e.key, record)}>
        <Menu.Item key="view">View</Menu.Item>
        <Menu.Item key="edit" >Edit</Menu.Item>
        <Menu.Item key="portfolio" >Edit Portfolio</Menu.Item>
        <Menu.Item key="leads">Leads</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </Menu>
    </div>
  );

    async function fetchData() {
        const article = {
            pageNum: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        };
        const headers = {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGE1NGRkOWZiMTA5OTMzZjg4MzY0MDciLCJjb250YWN0X2VtYWlsIjoicmFnaHZlbmRyYS5tb2RhbndhbEBhbnRjcmVhdGl2ZXMuY29tIiwiY29udGFjdF9uYW1lIjoiUmFnaGF2IiwiY29udGFjdF9waG9uZSI6Ijg3NTY4MDk4NzgiLCJpYXQiOjE2ODg1NTUyNzR9.sMRhMGvaYTc1cNEIiuMPvBl1j_VhiKt8mi3gDmQRuLU`,
            "Content-Type": "application/json",
        };
        const r = await fetch(`https://apidev.archinza.com/customer/list`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(article),
        });
        return r.json();
    }

    const tableParamsStringify = JSON.stringify(tableParams);

    const requestData = {
        pageNum: tableParams.pagination.current,
        pageSize: tableParams.pagination.pageSize,
    };

    useEffect(() => {
        callApi('customer/list','post',requestData).then((data) => {
            setData(data.data.customerList);
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data.data.customerCount,
                },
            });
        });
        setLoading(true);
    }, [tableParamsStringify]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const fetchMemberList = (inputFilters) => {

        callApi(`customer/list`,"post",{
            filters : inputFilters,
            pageNum: tableParams?.pagination?.current,
            pageSize: tableParams?.pagination?.pageSize,
        }).then(res => {
            if (res?.status === "Success") {
                setData(res?.data?.customerList);
               setCustomerCount(res?.data?.customerCount)
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };

    const onSumbit = () => {
        let searchFilters = {...filters};
        if(filters){
        fetchMemberList(searchFilters)
        }

        setModal2Open(false);
    };

    const columns = [
        {
            title: "S.No.",
            dataIndex: "_id",
            key: "sno",

            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setMemberCardState([true, record]);
                    },
                };
            },
        },
        {
            title: "Full Name",
            dataIndex: "contact_name",
            key: "name",
            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setMemberCardState([true, record]);
                    },
                };
            },
        },
        {
            title: "User Type",
            key: "userType",
            dataIndex: "user_type",
            render: (text) => <TagMemberList value={text}/>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setMemberCardState([true, record]);
                    },
                };
            },
        },
        {
            title: "Contact",
            dataIndex: "contact_phone",
            key: "contact",

            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setMemberCardState([true, record]);
                    },
                };
            },
        },
        {
            title: "Email Address",
            dataIndex: "contact_email",
            key: "email",

            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setMemberCardState([true, record]);
                    },
                };
            },
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",

            onCell: (record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setMemberCardState([true, record]);
                    },
                };
            },
        },
        {
            title: "Whatsapp",
            dataIndex: "preferred_mode",
            key: "whatsapp",
            render: (toggle) => <ToggleMemberList checked={toggle === "whatsapp"}/>,
        },
        {
            title: "More",
            dataIndex: "more",
            key: "more",
            render: (_, record) => (
                <Popover placement="bottom" content={() => moreMenuContent(record)} trigger="click">
                    <FilterOutlined/>
                </Popover>
            ),
        },
    ];
    const items = [
        {
            key: "1",
            label: "User Type",
            children: (
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: "#ffffff",
                            colorBorder: "#8C8C8C",
                            colorText: "#8C8C8C",
                            colorPrimary: "#1677FF",
                            fontFamily: "Noto Sans",
                            borderRadiusSM: "4",
                        },
                    }}
                >
                    {" "}
                    <Checkbox.Group
                        options={options}
                        onChange={(e) => handleFilterSelection('user_type', e)}
                        className="filter-user-type-wrapper"
                    />{" "}
                </ConfigProvider>
            ),
        },
        {
            key: "2",
            label: "Whatsapp Opted",
            children: <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: "#ffffff",
                        colorBorder: "#8C8C8C",
                        colorText: "#8C8C8C",
                        colorPrimary: "#1677FF",
                        fontFamily: "Noto Sans",
                        borderRadiusSM: "4",
                    },
                }}
            >
                <Radio.Group
                    onChange={(e) => handleFilterSelection('is_whatsapp_opt_in', e.target.value)}
                    className="filter-user-type-wrapper"
                >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </ConfigProvider>,
        },
        {
            key: "3",
            label: "Country",
            children: <ConfigProvider
                theme={{
                    token: {
                        colorBgContainer: "#ffffff",
                        colorBorder: "#8C8C8C",
                        colorText: "#8C8C8C",
                        colorPrimary: "#1677FF",
                        fontFamily: "Noto Sans",
                        borderRadiusSM: "4",
                    },
                }}
            >
                <Checkbox.Group
                    options={countryOptions}
                    onChange={(e) => handleFilterSelection('country', e)}
                    className="filter-user-type-wrapper"
                />
            </ConfigProvider>,
        },
    ];

    const handleAddMember = () => {
        if(memberType && memberType !== '') {
            navigate(`/members/add?member_type=${memberType}`)
        } else {
            showNotification('warning', 'Please select a member type')
        }
    }

    const MemberAddModel =
        <Modal
            style={{
                minWidth: 'max-content'
            }}
            width={'50%'}
            closeIcon={<div></div>}
            okText={'Next'}
            centered
            footer={<Button onClick={() => handleAddMember()} type={'primary'} size={'large'} className={'add-member-modal-button'}>Next</Button>}
            open={addMemberModal}
            onCancel={() => {
                setMemberType('')
                setAddMemberModal(false)
            }}
            closable={false}
            destroyOnClose={true}
        >
            <>
                <h1 className={'add-member-modal-title'}>Select user type</h1>
                <div className={'add-member-modal-content'}>
                    {
                        AddMemberData.map((data, index) => (
                            <AddMemberModalSelection setMemberType={setMemberType} memberType={memberType} key={index} image={data.image} title={data.title} />
                        ))
                    }
                </div>
            </>
        </Modal>

    return (
        <div className="member-list-table-wrapper">
            <div className="member-list-table-header">
                <h1 className="member-list-table-heading">{`All Members (${customerCount})`}</h1>
                {
                    MemberAddModel
                }
                <div className="member-list-table-header-right">
                    <Space>
                        <div
                            className="member-list-table-filter-wrapper"
                            onClick={() => setModal2Open(true)}
                        >
                            <p className="member-list-table-filter">Filters</p>
                            <FilterOutlined style={{color: "#8C8C8C"}}/>
                        </div>
                        <Modal
                            title=""
                            centered
                            open={modal2Open}
                            onOk={() => setModal2Open(false)}
                            onCancel={() => setModal2Open(false)}
                            footer={[
                                <Button
                                    key="back"
                                    onClick={() => setModal2Open(false)}
                                    className="filter-modal-cancel-button"
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={onSumbit}
                                    className="filter-modal-submit-button"
                                >
                                    Apply
                                </Button>,
                            ]}
                            width={290}
                        >
                            <div className="filter-modal-wrapper">
                                <div className="filter-modal-header">
                                    <FilterOutlined style={{color: "#6C757D"}}/>
                                    <p className="filter-modal-heading">Filters</p>
                                </div>
                                {/*<div className="filter-modal-search">*/}
                                {/*    <ConfigProvider*/}
                                {/*        theme={{*/}
                                {/*            token: {*/}
                                {/*                colorBgContainer: "#F5F5F5",*/}
                                {/*                colorBorder: "#ffffff",*/}
                                {/*                controlHeight: 32,*/}
                                {/*            },*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <Input size="large" placeholder="Search"/>*/}
                                {/*    </ConfigProvider>*/}
                                {/*</div>*/}
                                <div className="filter-modals-filterDropdowns">
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                padding: "0",
                                            },
                                        }}
                                    >
                                        <Collapse defaultActiveKey={["1"]} ghost items={items}/>
                                    </ConfigProvider>
                                </div>
                            </div>
                        </Modal>
                        <div className="member-list-table-search">
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorBgContainer: "#F5F5F5",
                                        colorBorder: "#ffffff",
                                        controlHeight: 32,
                                    },
                                }}
                            >
                                <Input
                                    size="large"
                                    placeholder="Search"
                                    prefix={
                                        <SearchOutlined
                                            style={{color: "#BABABA", marginRight: "6px"}}
                                        />
                                    }
                                    value={user}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </ConfigProvider>
                        </div>
                        <div className="member-list-table-add-button-wrapper">
                            <Button onClick={() => setAddMemberModal(true)} type="primary"
                                    className="member-list-table-add-button">
                                Add Member
                            </Button>
                        </div>
                    </Space>
                </div>
            </div>
            <Table
                style={{overflow: 'scroll', background: '#fff'}}
                columns={columns}
                dataSource={data}
                headerBg="#000000"
                className="member-list-table"
                loading={loading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                large
            />
            <MemberCard
                memberCardOpen={memberCardState[0]}
                setMemberCardState={setMemberCardState}
                memberObject={memberCardState[1]}
            />
        </div>
    );
}

export default MemberListTable;
