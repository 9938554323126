import React from "react";
import { Switch } from "antd";

import './index.css'

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

function ToggleMemberList({checked}) {
  return (
    <>
      <Switch checked={checked} onChange={onChange} className="member-list-table-toggle" />
    </>
  );
}

export default ToggleMemberList;
