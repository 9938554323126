import { NOT_INITIALIZED, AUTHENTICATED } from "../constants/authEnums";
import {
  FETCHING_STATUS,
  SET_AUTH_STATE,
  SET_IS_AUTHENTICATION,
  SET_USER_TYPE,
  SET_USER_ID,
} from "./appActionType";

const initialState = {
  authState: AUTHENTICATED,
  isFetching: false,
  isLoggingIn: false,
  isAuthenticated: true,
  userType: "",
  activeUserId: "",
  user: {}
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_STATUS:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case SET_AUTH_STATE:
      return {
        ...state,
        authState: action.authState,
      };

    case SET_IS_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.state,
      };

    case SET_USER_ID:
      return {
        ...state,
        activeUserId: action.id,
      };

    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.userType,
      };

    default:
      return state;
  }
};

export default AppReducer;
