import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Icon from "../../../../components/Icon";
import Label from "../../../../components/Label";
import Button from "../../../../components/Buttons";
import Inputs from "../../../../components/Input";
import { setAdminDetails, updateAdminDetails } from "../../adminAction";
import Box from "../../../../components/Box";
import useMediaUpload from "../../../../customHooks/useMediaUpload";

import { Input } from "antd";

const AdminProfileBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 40px;
  .changePassword {
    font-weight: 500;
    font-size: 12px;
    color: #d0004b;
    text-align: right;
    margin: 15px 0;
  }
`;
const Image = styled.div`
  height: 142px;
  width: 142px;
  background-color: grey;
  border-radius: 50%;
  position: relative;
  img {
    width: 100%;
    border-radius: 50%;
  }
  .Camera {
    height: 35.5px;
    width: 35.5px;
    border-radius: 50%;
    background: #ffffff;
    position: absolute;
    bottom: -2px;
    right: 16px;
    cursor: pointer;
  }
  .ChangeRemove {
    position: absolute;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
      0px 20px 32px rgba(96, 97, 112, 0.24);
    bottom: -100px;
    background: #ffffff;
    width: 145px;
    overflow: hidden;
    div {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: #d9d9d9;
      }
      &:first-child {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
`;
const MyProfile = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: #1a1a1a;
  margin-left: 40px;
`;

const ChangePhoto = styled.div`
  border: 1px solid white;
  text-align: center;
  color: #FFF;
  border-radius: 4px;
  margin: auto;
  width: 120px;
  padding: 10px;
  display: block;
  background-color: #0095ff;
  cursor: pointer;
  
  input[type=file] {
    display: none;
  }
`;

export default function AdminProfile() {
  const dispatch = useDispatch();
  const [isDropdown, setIsDropdown] = useState(false);
  const [isNext, setIsNext] = useState(false);

  const [changeProfile, setChangeProfile] = useState({});

  const adminDetails = useSelector((state) => state.adminReducer.admin);

  useEffect(() => {
    if (adminDetails) {
      setChangeProfile(adminDetails)
    }
  }, [adminDetails]);

  const handleChangeDetails = (keyName, keyValue) => {
    setIsNext(true);
    let data = { ...changeProfile }
    if (keyName && keyValue) {
      data[keyName] = keyValue
    }
    setChangeProfile(data);
  };

  const handleSubmit = () => {
    dispatch(updateAdminDetails(changeProfile?._id, changeProfile));
  }

  const removeProfilePic = () => {
    setIsDropdown(false);
    const data = {};
    data.profile_pic = "";
    dispatch(updateAdminDetails(changeProfile?._id, data));
  }

  const mediaType = "image";

  const { handleUpload, status } = useMediaUpload({
    mediaType,
    onSuccess: (res, fileUploaded) => {
      const data = {};
      data.profile_pic = res?.["media-url"];
      dispatch(updateAdminDetails(changeProfile?._id, data));
      setIsDropdown(false);
    },
  });

  return (
    <AdminProfileBox>
      <div className="d-flex align-items-center">
        <Image>
          <img src={changeProfile?.profile_pic} alt="" width="100%" height="100%" />
          <div
            className="Camera"
          >
            <Icon iconName="Camera" onClick={() => setIsDropdown(true)} />
          </div>
          {isDropdown && (
            <div className="ChangeRemove" style={{zIndex: 9}}>
              <div onClick={handleUpload}>
                Change Photo
              </div>
              <div onClick={() => removeProfilePic()}>Remove Photo</div>
            </div>
          )}
        </Image>
        <MyProfile>My Profile</MyProfile>
      </div>
      <div className="row">
        <div className="col-lg-6 mt-4">
          <Label mb="8px">
            Name<span style={{ color: "#FE1717" }}>*</span>
          </Label>
          <Input
            value={changeProfile?.contact_name}
            onChange={(e) =>
              handleChangeDetails("contact_name", e.target.value)
            }
            type="text"
            placeholder="Enter contact name"
            style={{ height: "42px" }}
          />
        </div>
        <div className="col-lg-6 mt-4">
          <Label mb="8px">
            Email Address<span style={{ color: "#FE1717" }}>*</span>
          </Label>
          <Input
            value={changeProfile?.contact_email}
            onChange={(e) => handleChangeDetails("contact_email", e.target.value)}
            type="text"
            placeholder="Enter mosque address"
            style={{ height: "42px" }}
          />
        </div>
        <div className="col-lg-6 mt-5" style={{display:"flex"}}>
        <div style={{width:"30%"}}>
           <Label mb="8px"style={{width:"20%"}}>
            code<span style={{ color: "#FE1717" }}>*</span>
          </Label>
          <Input
            value={changeProfile?.country_code}
            countryCodes={true}
            setCountryCode={(e) => handleChangeDetails("country_code", e)}
            // onChange={(e) => handleChangeDetails("country_code", e.target.value)}
            // placeholder="785-785-4644"
            style={{ height: "42px", width :"90%" }}
            type="number"
            // pattern="[0-9]"
          />
          </div>
          <div style={{width:"150%"}}>
          <Label mb="8px" style={{width:"100%"}}>
            Contact Number<span style={{ color: "#FE1717" }}>*</span>
          </Label>
          <Input
            value={changeProfile?.contact_phone}
            // countryCodes={true}
            // setCountryCode={(e) => handleChangeDetails("country_code", e)}
            onChange={(e) => handleChangeDetails("contact_phone", e.target.value)}
            placeholder="785-785-4644"
            style={{ height: "42px", width:"100%" }}
            type="number"
            pattern="[0-9]"
          />
          </div>
        
        </div>
        <div className="col-lg-6 mt-5">
          <Label mb="8px">
            Address<span style={{ color: "#FE1717" }}>*</span>
          </Label>
          <Input
            value={changeProfile?.address}
            // countryCodes={true}
            // setCountryCode={(e) => handleChangeDetails("country_code", e)}
            onChange={(e) => handleChangeDetails("address", e.target.value)}
            
            style={{ height: "42px" }}
            type="text"
           
          />
        </div>
      </div>
      <Button
        text="Save Details"
        width="15%"
        mt="4"
        mx="auto"
        height="60px"
        type={!isNext ? "disabled" : "primary"}
        onClick={() => handleSubmit()}
      />
    </AdminProfileBox>
  );
}
