export const FETCHING_STATUS = "FETCHING_STATUS";

export const SET_ADMIN = "SET_ADMIN";

export const SET_ACTIVE_USER_ID = "SET_ACTIVE_USER_ID";

export const SET_MOSQUE_DETAILS = "SET_MOSQUE_DETAILS";

export const ATTEMPT_TO_ADMIN_LOGIN = "ATTEMPT_TO_ADMIN_LOGIN";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILED = "ADMIN_LOGIN_FAILED";

export const FETCHING_SAAS_PLAN_LIST = "FETCHING_SAAS_PLAN_LIST";
export const SAAS_PLAN_LIST_SUCCESS = "SAAS_PLAN_LIST_SUCCESS";
export const SAAS_PLAN_LIST_FAILURE = "SAAS_PLAN_LIST_FAILURE";

export const FETCHING_MOSQUE_LIST = "FETCHING_MOSQUE_LIST";
export const FETCHING_MOSQUE_LIST_SUCCESS = "FETCHING_MOSQUE_LIST_SUCCESS";
export const FETCHING_MOSQUE_LIST_FAILURE = "FETCHING_MOSQUE_LIST_FAILURE";

export const FETCHING_MEMBER_LIST = "FETCHING_MEMBER_LIST";
export const FETCHING_MEMBER_LIST_SUCCESS = "FETCHING_MEMBER_LIST_SUCCESS";
export const FETCHING_MEMBER_LIST_FAILURE = "FETCHING_MEMBER_LIST_FAILURE";

export const UPDATE_MEMBER_INFO = "UPDATE_MEMBER_INFO";
export const UPDATE_MEMBER_INFO_SUCCESS = "UPDATE_MEMBER_INFO_SUCCESS";
export const UPDATE_MEMBER_INFO_FAILURE = "UPDATE_MEMBER_INFO_FAILURE";

export const UPDATE_ADDED_NEW_FLAG = "UPDATE_ADDED_NEW_FLAG";

export const ADD_NEW_MOSQUE = "ADD_NEW_MOSQUE";
export const ADD_NEW_MOSQUE_SUCCESS = "ADD_NEW_MOSQUE_SUCCESS";
export const ADD_NEW_MOSQUE_FAILURE = "ADD_NEW_MOSQUE_FAILURE";

export const UPDATE_MOSQUE = "UPDATE_MOSQUE";
export const UPDATE_MOSQUE_SUCCESS = "UPDATE_MOSQUE_SUCCESS";
export const UPDATE_MOSQUE_FAILURE = "UPDATE_MOSQUE_FAILURE";

export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";

export const SET_PLAN_DETAILS = "SET_PLAN_DETAILS";

export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_FOR_MEMBER_INFO_LIST = "UPDATE_PAGE_FOR_MEMBER_INFO_LIST";

export const RESET_LINK = "RESET_LINK";
export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";

export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";

export const ADD_NEW_PLAN = "ADD_NEW_PLAN";
export const ADD_NEW_PLAN_SUCCESS = "ADD_NEW_PLAN_SUCCESS";
export const ADD_NEW_PLAN_FAILURE = "ADD_NEW_PLAN_FAILURE";

export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAILURE = "UPDATE_PLAN_FAILURE";

export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = "DELETE_PLAN_FAILURE";

export const DASHBOARD_STATS_DATA = "DASHBOARD_STATS_DATA";
export const DASHBOARD_STATS_DATA_SUCCESS = "DASHBOARD_STATS_DATA_SUCCESS";
export const DASHBOARD_STATS_DATA_FAILURE = "DASHBOARD_STATS_DATA_FAILURE";

export const FETCH_ADMIN_PROFILE_DETAIL = 'FETCH_ADMIN_PROFILE_DETAIL';
export const FETCH_ADMIN_PROFILE_SUCCESS = 'FETCH_ADMIN_PROFILE_SUCCESS';
export const FETCH_ADMIN_PROFILE_FAILED = 'FETCH_ADMIN_PROFILE_FAILED';

export const UPDATE_MOSQUE_ADMIN_DETAILS = "UPDATE_MOSQUE_ADMIN_DETAILS";
export const UPDATE_MOSQUE_ADMIN_DETAILS_SUCCESS = "UPDATE_MOSQUE_ADMIN_DETAILS_SUCCESS";
export const UPDATE_MOSQUE_ADMIN_DETAILS_FAILURE = "UPDATE_MOSQUE_ADMIN_DETAILS_FAILURE";

export const UPDATE_ORG_DETAILS = "UPDATE_ORG_DETAILS";
export const UPDATE_ORG_DETAILS_SUCCESS = "UPDATE_ORG_DETAILS_SUCCESS";
export const UPDATE_ORG_DETAILS_FAILURE = "UPDATE_ORG_DETAILS_FAILURE";

export const FETCH_ENTITY_FIN_REVENUE_DETAILS = "FETCH_ENTITY_FIN_REVENUE_DETAILS";
export const FETCH_ENTITY_FIN_REVENUE_DETAILS_SUCCESS = "FETCH_ENTITY_FIN_REVENUE_DETAILS_SUCCESS";
export const FETCH_ENTITY_FIN_REVENUE_DETAILS_FAILURE = "FETCH_ENTITY_FIN_REVENUE_DETAILS_FAILURE";

export const SET_ENTITY_FIN_REVENUE_DETAILS = "SET_ENTITY_FIN_REVENUE_DETAILS";

export const FETCH_ORG_SUBS_COUNT_DETAILS = "FETCH_ORG_SUBS_COUNT_DETAILS";
export const FETCH_ORG_SUBS_COUNT_DETAILS_SUCCESS = "FETCH_ORG_SUBS_COUNT_DETAILS_SUCCESS";
export const FETCH_ORG_SUBS_COUNT_DETAILS_FAILURE = "FETCH_ORG_SUBS_COUNT_DETAILS_FAILURE";

export const FETCH_ORG_REVENUE_STATS_DETAILS = "FETCH_ORG_REVENUE_STATS_DETAILS";
export const FETCH_ORG_REVENUE_STATS_DETAILS_SUCCESS = "FETCH_ORG_REVENUE_STATS_DETAILS_SUCCESS";
export const FETCH_ORG_REVENUE_STATS_DETAILS_FAILURE = "FETCH_ORG_REVENUE_STATS_DETAILS_FAILURE";

export const FETCHING_APPROVAL_LIST = "FETCHING_APPROVAL_LIST";
export const FETCHING_APPROVAL_LIST_SUCCESS = "FETCHING_APPROVAL_LIST_SUCCESS";
export const FETCHING_APPROVAL_LIST_FAILURE = "FETCHING_APPROVAL_LIST_FAILURE";

export const MOSQUE_APPROVAL_STATUS = "MOSQUE_APPROVAL_STATUS";

export const LOADING_MEMBER_REMINDER_HISTORY = "LOADING_MEMBER_REMINDER_HISTORY";
export const LOAD_MEMBER_REMINDER_HISTORY = "LOAD_MEMBER_REMINDER_HISTORY";

export const LOADING_MEMBER_PAYMENT_HISTORY = "LOADING_MEMBER_PAYMENT_HISTORY";
export const LOAD_MEMBER_PAYMENT_HISTORY = "LOAD_MEMBER_PAYMENT_HISTORY";
