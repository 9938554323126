import React from "react";

import { Avatar, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import "./ChatItem.css";

const { Text } = Typography;

function truncateString(inputString, maxLength) {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength) + "..."; // Truncate and add ellipsis
  }
  return inputString;
}

const ChatItem = ({ data , onClick }) => {
  const { last_message_text, last_message_at } = data;
  const timestamp = new Date(last_message_at);
  const last_message_hours = timestamp
    .getUTCHours()
    .toString()
    .padStart(2, "0");
  const last_message_minutes = timestamp
    .getUTCMinutes()
    .toString()
    .padStart(2, "0");
  return (
    <div className="chat-item-wrapper" onClick={onClick} >
      <div className="chat-item-block1">
        <Avatar size="large" icon={<UserOutlined />} />
        <div className="chat-item-name-wrapper">
          <Text className="chat-item-name">Ryan Curtis</Text>
          <Text className="chat-item-name-message">
            {truncateString(last_message_text, 30)}
          </Text>
        </div>
      </div>
      <div className="chat-item-block2">
        <div className="chat-item-time">{`${last_message_hours}:${last_message_minutes}`}</div>
        <div className="chat-item-message-count">1</div>
      </div>
    </div>
  );
};

export default ChatItem;
